import { useTranslation } from 'react-i18next';
import analyticsBlurred from 'assets/images/analytics-blurred.jpg';
import { Box } from '@mui/system';
import Text from '../../../../components/text';

const UnderMaintenance: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{ width: '100%', position: 'relative' }} display="flex">
      {/* add a centered box */}
      <Box
        sx={{
          position: 'absolute',
          padding: '20px',
          backgroundColor: 'rgba(255, 255, 255, 1)',
          border: '1px solid rgba(0, 0, 0, 0.12)',
          width: '360px',
          maxWidth: 'calc(80%)',
          top: '25%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
        }}
      >
        <Text color="#666" fontSize={18} whiteSpace="pre-line">
          {t('maintenance_underway')}
        </Text>
      </Box>
      <img src={analyticsBlurred} alt="Analytics" style={{ width: '100%' }} />
    </Box>
  );
};

export default UnderMaintenance;
