import { TFunction } from 'i18next';
import * as yup from 'yup';

export const INVALID_EMAIL = 'Please use valid format of Email address';
export const INVALID_PASSWORD = 'Password should contains both number and letter';
export const INVALID_CONFIRM_PASSWORD = "Password doesn't match";

export const isEmail =
  // eslint-disable-next-line
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[a-zA-Z]).{6,50}$/;

export const emailRule = yup.string().required('Field is required').matches(isEmail, INVALID_EMAIL);

export const passwordRule = (t: TFunction) =>
  yup
    .string()
    .required(t('Field is required'))
    .min(6, t('Min 6 characters'))
    .max(50, t('Max 50 characters'))
    .matches(isPassword, INVALID_PASSWORD);

export const confirmPasswordRule = (name: string, t: TFunction) =>
  yup
    .string()
    .required(t('Field is required'))
    .oneOf([yup.ref(name)], INVALID_CONFIRM_PASSWORD);
