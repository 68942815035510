import React, { useState, useEffect } from 'react';
import { Pie } from '@visx/shape';
import { Group } from '@visx/group';
import { localPoint } from '@visx/event';
import { Tooltip, useTooltip, defaultStyles } from '@visx/tooltip';
import { Box } from '@mui/material';
import Text from 'components/text';
import { useTranslation } from 'react-i18next';

type PieChartProps = {
  width: number;
  height: number;
  data: { label: string; value: number; color: string }[];
};

type TooltipData = {
  label: string;
};

const tooltipStyles = {
  ...defaultStyles,
  background: '#f4f4f4',
  border: '1px solid #ccc',
  color: '#333',
};

const SocialMediaAudiencePieChart: React.FC<PieChartProps> = ({ width, height, data }) => {
  const { t } = useTranslation();
  const radius = Math.min(width, height) / 2.2;
  const centerY = height / 2;
  const centerX = width / 2;

  const [male, setMale] = useState<number>(0);
  const [female, setFemale] = useState<number>(0);

  const { tooltipData, tooltipLeft, tooltipTop, showTooltip, hideTooltip } =
    useTooltip<TooltipData>();
  const [hoveredArc, setHoveredArc] = useState<string | null>(null);

  const handleMouseOver = (event: React.MouseEvent, arc: any) => {
    const { x, y } = localPoint(event) || { x: 0, y: 0 };
    showTooltip({
      tooltipData: { label: arc.data.label },
      tooltipLeft: x,
      tooltipTop: y,
    });
    setHoveredArc(arc.data.label);
  };

  const handleMouseOut = () => {
    hideTooltip();
    setHoveredArc(null);
  };

  useEffect(() => {
    data.forEach((item) => {
      if (item.label === 'Male') {
        setMale(item.value);
      } else {
        setFemale(item.value);
      }
    });
  }, [data]);

  return (
    <Box position="relative" width={width}>
      <Box position="absolute" top={115} left={0} right={0}>
        <Box display="flex" alignItems="center" justifyContent="center" gap={0.6} marginBottom={1}>
          <Box bgcolor="#f96565" width={16} height={16} borderRadius={9} />
          <Text>
            {male}% {t('Male')}
          </Text>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" gap={0.6}>
          <Box bgcolor="#fba5a5" width={16} height={16} borderRadius={9} />
          <Text>
            {female}% {t('Female')}
          </Text>
        </Box>
      </Box>

      <svg width={width} height={height}>
        <Group top={centerY} left={centerX}>
          <Pie
            data={data}
            pieValue={(d) => d.value}
            outerRadius={radius}
            innerRadius={radius / 1.5}
            cornerRadius={5}
            padAngle={0.025}
            markerWidth={1}
            markerHeight={1}
          >
            {(pie) =>
              pie.arcs.map((arc) => (
                <g key={arc.data.label}>
                  <path
                    d={pie.path(arc) || undefined}
                    fill={arc.data.color}
                    stroke={hoveredArc === arc.data.label ? '#000' : 'none'}
                    strokeWidth={hoveredArc === arc.data.label ? 2 : 1}
                    onMouseOver={(e) => handleMouseOver(e, arc)}
                    onMouseOut={handleMouseOut}
                  />
                </g>
              ))
            }
          </Pie>
        </Group>
      </svg>

      {tooltipData && (
        <Tooltip top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
          {tooltipData.label}
        </Tooltip>
      )}

      <Box>
        <Text textAlign="center" display="block">
          {t('Overall Audience Percentage')}
        </Text>
      </Box>
    </Box>
  );
};

export default SocialMediaAudiencePieChart;
