import React from 'react';
import { Box, Grid } from '@mui/material';
import { useUserData } from 'hooks/useUserData';
import Text from 'components/text';
import { Link, useParams } from 'react-router-dom';
import { ICourse, courses } from './courses';
import { useTranslation } from 'react-i18next';

const ViewCourse: React.FC = () => {
  const { t } = useTranslation();
  const { permissionsData } = useUserData();
  const { slug } = useParams<{ slug: string }>();
  const course: ICourse | undefined = courses.hasOwnProperty(slug)
    ? courses[slug as keyof typeof courses]
    : undefined;

  if (!permissionsData?.education) {
    return (
      <Box padding={3}>
        <Text>
          {t(
            'Sorry, the page you are looking for does not exist, please contact with us if you are facing an issues.'
          )}
        </Text>
      </Box>
    );
  }
  if (!course) {
    return <div>{t('Course not found')}</div>;
  }

  return (
    <Box border={1} width="100%">
      {course.type === 'embed' && (
        <iframe
          title="Songfund Education"
          sandbox="allow-scripts allow-same-origin"
          src={
            course.type === 'embed'
              ? '/edu/edu-contents-embedding/' + slug
              : '/edu/edu-contents-embedding/' + slug + '.pdf'
          }
          style={{
            width: '100%',
            height: '100%',
            border: 'none',
          }}
          allowFullScreen={true}
          seamless
        ></iframe>
      )}

      {course.type === 'pdf' && (
        <embed
          src={`/edu/edu-contents-embedding/${slug}.pdf#toolbar=0&navpanes=0`}
          width="100%"
          height="100%"
          type="application/pdf"
        />
      )}
    </Box>
  );
};

export default ViewCourse;
