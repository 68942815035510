import * as yup from 'yup';
import { passwordRule, confirmPasswordRule } from 'utils/validation-rules';
import { TFunction } from 'i18next';

export const ChangePasswordSchema = (t: TFunction) =>
  yup.object({
    oldPassword: passwordRule(t),
    newPassword: passwordRule(t),
    passwordConfirm: confirmPasswordRule('newPassword', t),
  });
