import '../../i18n/config';
import { useEffect, useMemo, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Header from 'components/header';

import { IPermissions, useUserData } from 'hooks/useUserData';

import api from 'utils/api';

import { authorizedRoutes, unauthorizedRoutes } from './routes';
import CookieBanner from '../../components/cookie-banner';
import { Box } from '@mui/material';
import { WithTranslation, withTranslation } from 'react-i18next';
import React from 'react';

const App: React.FC<WithTranslation> = ({ t }) => {
  const [loading, setLoading] = useState(true);
  const { userData, updateUserData, updatePermissionData } = useUserData();

  useEffect(() => {
    const accessToken = localStorage.getItem('Authorization');

    const fetchUserData = async () => {
      try {
        const data = await api.auth.getProfile();
        updateUserData({ ...data, isAuthorized: true });

        const permissions: IPermissions = await api.auth.getPermissions();
        updatePermissionData(permissions);

        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    };

    if (accessToken) {
      fetchUserData().catch((err) => {
        setLoading(false);
        console.error(err);
      });
    } else {
      setLoading(false);
      updateUserData({ isAuthorized: false });
    }
  }, [updateUserData, updatePermissionData]);

  /*
    useEffect(() => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.innerHTML = `
        window.$crisp = [];
        window.CRISP_WEBSITE_ID = '2e7840ee-2d6f-400d-9c0f-4c9a10d1ffb2';
        (function () {
          var d = document;
          var s = d.createElement('script');
          s.src = 'https://client.crisp.chat/l.js';
          s.async = 1;
          d.getElementsByTagName('head')[0].appendChild(s);
        })();
      `;

      document.head.appendChild(script);

      return () => {
        document.head.removeChild(script);
      };
    }, []);
  */

  const currentRoutes = useMemo(
    () => (userData.isAuthorized ? authorizedRoutes : unauthorizedRoutes),
    [userData]
  );

  return (
    <>
      <Header />
      {loading ? (
        <Box
          bgcolor="#000"
          color="#ddd"
          fontSize="22px"
          textAlign="center"
          sx={{
            height: 'calc(100vh - 72px)',
          }}
        >
          <Box paddingTop={10}>{t('Loading....')}</Box>
        </Box>
      ) : (
        <Switch>
          {currentRoutes.map(({ path, component, exact = false }) => (
            <Route key={path} path={path} exact={exact} component={component} />
          ))}

          <Redirect to="/" />
        </Switch>
      )}
      <CookieBanner />
    </>
  );
};

export default withTranslation()(App);
