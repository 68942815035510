import axios, { AxiosInstance, Method, AxiosRequestConfig, CancelTokenSource } from 'axios';
import { getAuthData, setAuthData, clearAuthData } from './authData';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

export const API_URL = process.env.REACT_APP_API_URL;

export interface AxiosResponseData {
  [key: string]: any;
}

const instance: AxiosInstance = axios.create({
  baseURL: `${API_URL}`,
  responseType: 'json',
});

/* request interceptor */
instance.interceptors.request.use((request) => {
  request.headers = { ...request.headers, ...getAuthData(), language: i18next.language };
  return request;
});

/* response interceptor */
instance.interceptors.response.use(
  ({ data }) => {
    if (data?.access_token) setAuthData({ Authorization: `Bearer ${data.access_token}` });
    return data;
  },
  (responseData = {}) => {
    const { response, message } = responseData;
    const data = response?.data;
    const status = response?.status;

    if (status === 401) {
      clearAuthData();
    }

    return Promise.reject({ data, message, status });
  }
);

type URL = string | ((id?: number | string) => string);

interface IArg extends AxiosRequestConfig {
  id?: number | string;
  params?: { [key: string]: any };
}

export type GetAxiosResponse = <T = AxiosResponseData>(args?: IArg) => Promise<T>;

export type TMakeAxiosRequest = (url: URL, method: Method) => GetAxiosResponse;

const makeAxiosRequest: TMakeAxiosRequest =
  (url, method) =>
  (args = {}) => {
    const { id, params } = args;
    return instance({
      url: typeof url === 'function' ? url(id) : url,
      method,
      params,
      ...args,
    }) as Promise<any>;
  };

const createAxiosCancelToken = (): CancelTokenSource => axios.CancelToken.source();

export { createAxiosCancelToken };

export default makeAxiosRequest;
