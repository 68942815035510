import React, { memo, useMemo, useState, useEffect } from 'react';
import { Link, useLocation, useHistory, NavLink } from 'react-router-dom';
// mui components
import {
  Avatar,
  Drawer,
  Grid,
  Menu,
  MenuItem,
  ClickAwayListener,
  MenuList,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
// hooks
import { useUserData } from 'hooks/useUserData';
// icons
import { ReactComponent as DealIcon } from 'assets/icons/deal_2.svg';
import { ReactComponent as MusicIcon } from 'assets/icons/music.svg';
// import { ReactComponent as SettingIcon } from 'assets/icons/setting.svg';
import { ReactComponent as LogOutIcon } from 'assets/icons/log-out.svg';
import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import { ReactComponent as MenuIcon } from 'assets/icons/menu.svg';
import { ReactComponent as EducationIcon } from 'assets/icons/education.svg';
import { ReactComponent as AnalyticsIcon } from 'assets/icons/analytics.svg';
import { ReactComponent as ReferralIcon } from 'assets/icons/referral.svg';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow_left.svg';
import avatarPlaceholderPath from 'assets/icons/avatar_placeholder.svg';
import avatarPlaceholderDarkPath from 'assets/icons/avatar_placeholder_dark.svg';
import { ReactComponent as AdminIcon } from 'assets/icons/admin.svg';

// constants
import { authPages } from 'utils/constants';
// styles
import { colors } from 'styles/colors';
import { useWidth } from 'hooks/useWidth';
import Switcher from './lang/switcher';
import { withTranslation, WithTranslation } from 'react-i18next';

const StyledMobileTabs = styled(Grid)(() => ({
  width: '100vw',
  height: '100%',
  padding: '100px 0 0 25px',
  backgroundColor: colors.main.black,
}));

const StyledTab = styled(NavLink)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  boxSizing: 'border-box',
  alignItems: 'center',
  width: '100%',
  height: '56px',
  padding: '12px 27px',
  textDecoration: 'none',
  ...theme.fontStyles.subtext,
  color: theme.colors.text.light.placeholder,
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    window: '4px',
    height: '100%',
    backgroundColor: theme.colors.main.yellow,
    display: 'none',
  },
  marginBottom: '16px',
  '& span': {
    marginLeft: '16px',
  },
  '&.active': {
    color: theme.colors.main.white,
    '& path': {
      fill: theme.colors.main.white,
    },
    borderLeft: '4px solid yellow',
  },
  '&::after.active': {
    display: 'block',
  },
}));

const StyledMenu = styled(Menu)(({ theme }) => ({
  padding: 0,
  top: 10,
  borderRadius: '4px',
  '& .MuiMenuItem-root': {
    ...theme.fontStyles.subtext,
    color: theme.colors.text.light.placeholder,
    padding: '16px',
    minWidth: '140px',
  },
}));

const StyledArrow = styled(ArrowLeft)(() => ({
  width: '16px',
  marginLeft: '5px',
  transition: 'all 300ms',
  '& path': {
    fill: 'none !important',
  },
}));

const Header: React.FC<WithTranslation> = ({ t, i18n }) => {
  const location = useLocation();

  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { isMobile } = useWidth();

  const [open, setOpen] = useState(false);

  const { logOut, userData, permissionsData } = useUserData();

  const isDarkColors = useMemo(() => {
    if (authPages.includes(location.pathname)) return true;
    const [, pageName = ''] = location.pathname.split('/');

    if (!pageName) return true;

    return [
      '/contact',
      '/new-password',
      '/profile',
      '/deals',
      '/education',
      '/analytics',
      '/referral',
      '/admin',
    ].includes(`/${pageName}`);
  }, [location.pathname]);

  useEffect(() => {
    setOpen(false);
  }, [location.pathname, userData.isAuthorized]);

  const handleClose = () => setOpen(false);

  return (
    <>
      {isMobile && (
        <Drawer open={open} hideBackdrop variant="persistent" sx={{ zIndex: 100 }}>
          <StyledMobileTabs
            container
            flexDirection="column"
            sx={{
              backgroundColor: isDarkColors ? colors.main.black : colors.main.white,
            }}
          >
            <StyledTab to="/deals" activeClassName="active">
              <DealIcon />
              <span>{t('Deals')}</span>
            </StyledTab>
            <StyledTab to="/profile">
              <MusicIcon />
              <span>{t('Profile')}</span>
            </StyledTab>

            {permissionsData.education && (
              <StyledTab
                to="/education"
                onClick={() => {
                  if (window.location.pathname.trim() !== '/education') {
                    window.location.href = '/education';
                  }
                }}
              >
                <EducationIcon />
                <span>{t('Education')}</span>
              </StyledTab>
            )}

            {permissionsData.analytics && (
              <StyledTab to="/analytics">
                <AnalyticsIcon />
                <span>{t('Analytics')}</span>
              </StyledTab>
            )}

            <StyledTab to="/referral">
              <ReferralIcon />
              <span>{t('Referral')}</span>
            </StyledTab>

            {permissionsData.admin && (
              <StyledTab to="/admin">
                <AdminIcon />
                <span>{t('Admin')}</span>
              </StyledTab>
            )}

            <StyledTab onClick={logOut} to="/" activeClassName="">
              <LogOutIcon />
              <span>{t('Log out')}</span>
            </StyledTab>
            <Switcher isDarkColors={true} />
          </StyledMobileTabs>
        </Drawer>
      )}
      <Grid
        container
        justifyContent="center"
        sx={{
          position: open && isMobile ? 'fixed' : isMobile ? 'sticky' : 'static',
          top: 0,
          left: 0,
          zIndex: 9999,
          height: '72px',
          boxShadow: `inset 0px -1px 0px ${
            isDarkColors ? colors.main.black : colors.background.light.b4
          }`,
          backgroundColor: isDarkColors ? colors.main.black : colors.main.white,
          '& path': {
            fill: isDarkColors ? colors.main.white : colors.main.black,
          },
        }}
      >
        <Grid container alignItems="center" sx={{ maxWidth: '1150px', padding: '20px' }}>
          <Grid container item xs={6}>
            <Link to="/">
              <Logo />
            </Link>
          </Grid>

          {userData.isAuthorized &&
            (isMobile ? (
              <Grid container item xs={6} justifyContent="flex-end">
                {open ? (
                  <Close onClick={handleClose} />
                ) : (
                  <MenuIcon onClick={() => setOpen(true)} />
                )}
              </Grid>
            ) : (
              <Grid container item xs={6} justifyContent="flex-end">
                <Box display="flex">
                  <Grid
                    onClick={(event) => {
                      setOpen((prev) => !prev);
                      setAnchorEl(event.currentTarget);
                    }}
                    sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                  >
                    <Avatar
                      src={isDarkColors ? avatarPlaceholderDarkPath : avatarPlaceholderPath}
                      alt="avatar"
                      sx={{ width: '40px', height: '40px' }}
                    />
                    <StyledArrow
                      sx={{
                        transform: open ? 'rotate(90deg)' : 'rotate(-90deg)',
                        '& path': {
                          stroke: isDarkColors ? colors.main.white : colors.main.black,
                        },
                      }}
                    />
                  </Grid>
                  <StyledMenu open={open} variant="selectedMenu" anchorEl={anchorEl}>
                    <Grid container>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList sx={{ padding: 0 }}>
                          <MenuItem
                            onClick={() => {
                              history.push('/deals');
                              handleClose();
                            }}
                          >
                            {t('Deals')}
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              history.push('/profile');
                              handleClose();
                            }}
                          >
                            {t('Profile')}
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              logOut();
                            }}
                          >
                            {t('Log out')}
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Grid>
                  </StyledMenu>
                  <Switcher isDarkColors={isDarkColors} />
                </Box>
              </Grid>
            ))}
          {!userData.isAuthorized && (
            <Grid item xs={6} justifyItems="flex-end">
              <Switcher isDarkColors={true} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default memo(withTranslation()(Header));
