import React, { useCallback, memo, useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useHistory } from 'react-router-dom';
// mui components
import { Grid, Typography } from '@mui/material';
// layouts
import AuthLayout from 'layouts/auth-layout';
// components
import StyledInputContainer from '../components/styled-input-container';
import StyledButton from '../components/styled-button';
import StyledLink from '../components/styled-link';
// controllers
import PasswordInputController from 'controllers/password-input-controller';
// validations
import { ResetPasswordSchema } from './validation';
// styles
import { fontStyles } from 'styles/font-styles';
import { colors } from 'styles/colors';

import api from 'utils/api';
import { useError } from 'hooks/useError';
import ErrorModal from 'components/error-modal';
import DialogModal from 'components/dialog-modal';
import { useUserData } from 'hooks/useUserData';
import { useTranslation } from 'react-i18next';

const ResetPassword: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const { message, setError } = useError();
  const [loading, setLoading] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [redirectOnModalClose, setRedirectOnModalClose] = useState('');

  const form = useForm({ resolver: yupResolver(ResetPasswordSchema(t)) });
  const { userData } = useUserData();

  useEffect(() => {
    const qs: URLSearchParams = new URLSearchParams(search);
    const resetPasswordToken = decodeURIComponent(qs.get('resetPasswordToken') || '');
    if (!resetPasswordToken) {
      setError(t('Invalid token, try to reset the password again.'));
      setOpenErrorModal(true);
      setRedirectOnModalClose('/forgot-password');
    } else {
      form.setValue('reset_password_token', resetPasswordToken);
      history.replace('/new-password');
    }
    // eslint-disable-next-line
  }, []);

  const onSubmit = useCallback(async (formValues: any) => {
    const data = {
      newPassword: formValues.password,
      passwordConfirm: formValues.password_confirm,
      resetPasswordToken: formValues.reset_password_token,
    };
    try {
      setLoading(true);
      await api.auth.confirmResetPassword({ data });
      setLoading(false);
      setOpenSuccessModal(true);
    } catch (error) {
      setLoading(false);
      console.log(error);
      setError(t('Unable to reset password, please try again!'));
      setRedirectOnModalClose('/forgot-password');
      setOpenErrorModal(true);
    }
    // eslint-disable-next-line
  }, []);

  const handleCloseErrorModal = () => {
    if (redirectOnModalClose) {
      history.push(redirectOnModalClose);
    } else {
      setError('');
      setOpenErrorModal(false);
    }
  };

  return (
    <FormProvider {...form}>
      <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
        <AuthLayout>
          <Grid container flexDirection="column" alignItems="center" sx={{ maxWidth: '500px' }}>
            <Typography sx={{ marginBottom: '8px', ...fontStyles.h3, color: colors.main.white }}>
              {t('Hey there!')}
            </Typography>
            <Typography
              sx={{ marginBottom: '30px', ...fontStyles.body, color: colors.text.dark.body }}
            >
              {t('Choose a new password for your account')}
            </Typography>
            <StyledInputContainer>
              <PasswordInputController
                dark
                withError
                name="password"
                variant="filled"
                label={t('Create password')}
              />
            </StyledInputContainer>
            <StyledInputContainer>
              <PasswordInputController
                dark
                withError
                variant="filled"
                name="password_confirm"
                label={t('Confirm password')}
              />
            </StyledInputContainer>
            <StyledButton type="submit" loading={loading}>
              {t('Submit')}
            </StyledButton>
            {!userData.isAuthorized && (
              <Grid container justifyContent="center" alignItems="baseline">
                <StyledLink to="/sign-in">{t('Back to Log in')}</StyledLink>
              </Grid>
            )}
          </Grid>
        </AuthLayout>
      </form>
      <ErrorModal open={openErrorModal} handleClose={handleCloseErrorModal} message={message} />
      <DialogModal
        dark
        open={openSuccessModal}
        maxWidth="410px"
        withActions={false}
        handleClose={() => history.push('/sign-in')}
      >
        <Grid container justifyContent="center">
          <Grid container justifyContent="center" sx={{ maxWidth: '320px' }}>
            <Typography
              sx={{
                fontSize: '18px',
                lineHeight: '26px',
                fontWeight: 'lighter',
                color: colors.text.dark.label,
                textAlign: 'center',
              }}
            >
              {t('Your accounts password has updated successfully!')}
            </Typography>
            {!userData.isAuthorized && (
              <StyledLink to="/sign-in" sx={{ marginTop: '32px' }}>
                {t('Back to Log in')}
              </StyledLink>
            )}
            {userData.isAuthorized && (
              <StyledLink to="/deals" sx={{ marginTop: '32px' }}>
                {t('Continue')}
              </StyledLink>
            )}
          </Grid>
        </Grid>
      </DialogModal>
    </FormProvider>
  );
};

export default memo(ResetPassword);
