import { Box, Grid } from '@mui/material';
import AutocompleteInput, { IBaseOption, IOption } from 'components/inputs/autocomplete-input';
import Text from 'components/text';
import { useUserData } from 'hooks/useUserData';
import { useEffect, useRef, useState } from 'react';
import api from 'utils/api';

// import src/sample-data/Denise_data_Matt_Duncan.json data file
import MattDuncan_AveragePerformance from '../../../../sample-data/Matt_Duncan_catalogue_average_performance.json';
import OnHold_data_Matt_Duncan from '../../../../sample-data/OnHold_data_Matt_Duncan.json';
import Snafu_catalog_performance from '../../../../sample-data/Snafu_catalog_performance.json';
import CompletionRateChart, {
  CompletionRateData,
  DataPointWithExtra,
  Legend,
} from './CompletionRateChart';
import CompletionRateChartLegend from './CompletionRateChartLegend';
import CompletionRateLeaderBoard, { ITrackRank } from './CompletionRateLeaderboard';
import Loading from './Loading';
import { useTranslation } from 'react-i18next';

interface IArtistProfile {
  spArtistId: string;
  artistName: string;
}

interface ITrack {
  spTrackId: string;
  spArtistId: string;
  trackName: string;
  isrc: string;
}

type ApiDataPointWithExtra = Omit<DataPointWithExtra, 'x' | 'y'> &
  Partial<Pick<DataPointWithExtra, 'x' | 'y'>>;
type ApiTrackDataPointWithExtra = Omit<DataPointWithExtra, 'x' | 'y'> &
  Partial<Pick<DataPointWithExtra, 'x' | 'y'>> &
  Required<Pick<DataPointWithExtra, 'streamPctLength'>>;

export type CompletionRateApiData = {
  track: ApiTrackDataPointWithExtra[];
  artist: ApiDataPointWithExtra[];
  snafu: ApiDataPointWithExtra[];
};

interface CompletionRateRankData {
  page: number;
  totalCount: number;
  trackRank?: ITrackRank;
  records?: ITrackRank[];
}

export default function TrackAnalytics() {
  const { t } = useTranslation();
  const { permissionsData } = useUserData();

  const elementRef = useRef(null);
  const [loading, setLoading] = useState(true);

  const [artistOptions, setArtistOptions] = useState<IBaseOption<string>[]>([]);
  const [selectedArtistOption, setSelectedArtistOption] = useState<IBaseOption<string> | null>(
    null
  );

  const [trackOptions, setTrackOptions] = useState<IBaseOption<string>[]>([]);
  const [selectedTrackOption, setSelectedTrackOption] = useState<IBaseOption<string> | null>(null);

  const [showCompletionRate, setShowCompletionRate] = useState(false);
  const [completionRateData, setCompletionRateData] = useState<CompletionRateData | undefined>(
    undefined
  );

  const [trackRanksReady, setTrackRanksReady] = useState<boolean>(false);

  const [trackRanks, setTrackRanks] = useState<ITrackRank[] | undefined>(undefined);
  const [totalCount, setTotalCount] = useState<number | undefined>(undefined);

  const [page, setPage] = useState<number | undefined>(undefined);
  const [perPage, setPerPage] = useState<number | undefined>(10);

  const onPagingationChange = ({ page: pg, perPage: pp }: { page: number; perPage: number }) => {
    setPage(pg);
    setPerPage(pp);
  };

  const [chartLegend, setChartLegend] = useState<Legend>([
    {
      key: 'track',
      label: t('Selected Track Performance'),
      color: '#7dfc00',
      invertColor: '#000',
      enabled: true,
      value: undefined,
    },
    {
      key: 'artist',
      label: t('Catalogue Average Performance'),
      color: '#0ec434',
      invertColor: '#000',
      enabled: true,
      value: undefined,
    },
    {
      key: 'snafu',
      label: t('Industry Average Performance'),
      color: '#228c68',
      invertColor: '#000',
      enabled: true,
      value: undefined,
    },
  ]);

  const populateData = (data: CompletionRateApiData) => {
    const track: DataPointWithExtra[] = data.track.map((i) => ({
      ...i,
      x: i.streamPctLength,
      y: i.remainingStreams,
    }));

    const trackLength = Math.max(...data.track.map((i) => i.streamPctLength));

    const artist: DataPointWithExtra[] = data.artist.map((i) => ({
      ...i,
      x: (i.completionRate * trackLength) / 100.0,
      y: i.remainingStreams,
    }));

    const snafu: DataPointWithExtra[] = data.snafu.map((i) => ({
      ...i,
      x: (i.completionRate * trackLength) / 100.0,
      y: i.remainingStreams,
    }));

    setCompletionRateData({
      track,
      artist,
      snafu,
    });
  };

  useEffect(() => {
    if (page !== undefined) {
      setPage(undefined);
      setTrackRanksReady(false);
    }
  }, [selectedArtistOption, selectedTrackOption]);

  useEffect(() => {
    setLoading(true);
    api.analytics.getCompletionRateArtists<IArtistProfile[]>().then((profiles) => {
      const options = profiles.map(({ spArtistId, artistName }) => ({
        value: spArtistId,
        label: artistName,
      }));
      setArtistOptions(options);
      if (options.length > 0) {
        setSelectedArtistOption(options[0]);
      }
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (selectedArtistOption === null) {
      setTrackOptions([]);
      return;
    }

    setLoading(true);
    api.analytics
      .getCompletionRateTracks<ITrack[]>({
        id: 'completion_rate_tracks',
        params: { spProfile: selectedArtistOption.value },
      })
      .then((records) => {
        const options = records.map(({ spTrackId, trackName }) => ({
          value: spTrackId,
          label: trackName,
        }));
        setTrackOptions(options);
        if (options.length > 0) {
          setSelectedTrackOption(options[0]);
        }
        setLoading(false);
      });
  }, [selectedArtistOption]);

  useEffect(() => {
    if (!selectedTrackOption || !selectedArtistOption) {
      setShowCompletionRate(false);
      return;
    }
    setLoading(true);
    api.analytics
      .getCompletionRateAnalytics<CompletionRateApiData>({
        id: 'completion_rate_analytics',
        params: { spProfile: selectedArtistOption.value, spTrackId: selectedTrackOption.value },
      })
      .then((data) => {
        populateData(data);
        setLoading(false);
        setShowCompletionRate(true);
      });
  }, [selectedTrackOption]);

  useEffect(() => {
    if (!selectedTrackOption || !selectedArtistOption) {
      return;
    }
    if (perPage == undefined) {
      return;
    }
    api.analytics
      .getCompletionRateRank<{ trackRank: ITrackRank | null | undefined }>({
        id: 'completion_rate_rank',
        params: { spProfile: selectedArtistOption.value, spTrackId: selectedTrackOption.value },
      })
      .then((data) => {
        const p = data.trackRank ? Math.floor((data.trackRank.rank - 1) / perPage) + 1 : 1;
        setPage(p);
      });
  }, [selectedTrackOption, perPage]);

  useEffect(() => {
    if (!selectedTrackOption || !selectedArtistOption || page === undefined) {
      return;
    }
    api.analytics
      .getCompletionRateRankList<CompletionRateRankData>({
        id: 'completion_rate_rank_list',
        params: { spProfile: selectedArtistOption.value, page, perPage },
      })
      .then((data) => {
        setTotalCount(data.totalCount);
        setTrackRanks(data.records);
        setTrackRanksReady(data.totalCount !== undefined && data.records !== undefined);
      });
  }, [page, perPage]);

  const maxSeconds = Math.max(...OnHold_data_Matt_Duncan.map((item) => item.stream_pct_length));

  const selectedCompletionRateData = {
    track: OnHold_data_Matt_Duncan.map((item: any) => ({
      x: item.stream_pct_length,
      y: item.remaining_streams,
      extra: {
        ...item,
      },
    })),
    artist: MattDuncan_AveragePerformance.map((item: any) => ({
      x: (item.completion_rate / 100.0) * maxSeconds,
      y: item.remaining_streams,
    })),
    snafu: Snafu_catalog_performance.sort((a, b) => a.completion_rate - b.completion_rate).map(
      (item: any) => ({
        x: (item.completion_rate / 100.0) * maxSeconds,
        y: item.mean_remaining_streams,
      })
    ),
  };

  return (
    <Grid container flexDirection="column" padding={2} marginBottom={10}>
      <Grid container spacing={2}>
        {permissionsData.admin && (
          <Grid item xs={12} md={6}>
            <Box>
              <Box paddingBottom={2}>
                <Text>{t('Admin Artist Selection')}:</Text>
              </Box>
              <Box flex={1}>
                <AutocompleteInput
                  matchFrom="any"
                  size="small"
                  dark={false}
                  disabled={loading}
                  name="spotifyId"
                  error={loading ? false : selectedArtistOption === null}
                  onChange={(value) => {
                    setSelectedArtistOption(
                      artistOptions.find(({ value: v }) => v === value) || null
                    );
                  }}
                  disableClearable={true}
                  value={selectedArtistOption}
                  options={artistOptions}
                  label={t('Select Artist')}
                  selectOnFocus
                />
              </Box>
            </Box>
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <Box>
            <Box paddingBottom={2}>
              <Text>{t('Track Selection')}:</Text>
            </Box>
            <Box flex={1}>
              <AutocompleteInput
                matchFrom="any"
                size="small"
                dark={false}
                disabled={loading}
                name="spotifyId"
                error={loading ? false : selectedTrackOption === null}
                onChange={(value) => {
                  setSelectedTrackOption(trackOptions.find(({ value: v }) => v === value) || null);
                }}
                disableClearable={false}
                value={selectedTrackOption}
                options={trackOptions}
                label={t('Select Track')}
                selectOnFocus
              />
            </Box>
          </Box>
        </Grid>
      </Grid>

      {!loading && (
        <>
          <Box
            marginTop={2}
            borderRadius={2}
            padding={2}
            border={1}
            borderColor="#DFE1E6"
            maxWidth="100%"
            overflow="hidden"
          >
            {showCompletionRate ? (
              <>
                <Box marginBottom={2}>
                  <Text sx={{ fontSize: 20, fontWeight: 'bold' }}>
                    {t('Percentage of Streams at Each Point of the Track')}
                  </Text>
                </Box>

                {selectedCompletionRateData && completionRateData ? (
                  <Grid spacing={'20px'}>
                    <Box width="100%" paddingTop={2} paddingBottom={2}>
                      <CompletionRateChart data={completionRateData} legend={chartLegend} />
                    </Box>
                    <CompletionRateChartLegend legend={chartLegend} onChange={setChartLegend} />
                  </Grid>
                ) : (
                  <Box padding={2}>{t('No data available')}</Box>
                )}
              </>
            ) : (
              <Box width="100%">{t('Please select track to see the data')}</Box>
            )}
          </Box>
          {selectedArtistOption && selectedTrackOption && (
            <Box
              marginTop={2}
              borderRadius={2}
              padding={2}
              border={1}
              borderColor="#DFE1E6"
              maxWidth="100%"
              overflow="hidden"
            >
              <Box marginBottom={2}>
                <Text sx={{ fontSize: 20, fontWeight: 'bold' }} whiteSpace="nowrap">
                  {t('Your Track Ranking')}
                </Text>
                {trackRanksReady && page && perPage && trackRanks && totalCount ? (
                  <CompletionRateLeaderBoard
                    page={page}
                    onPaginationChange={onPagingationChange}
                    perPage={perPage}
                    totalCount={totalCount}
                    trackRanks={trackRanks}
                    spTrackId={selectedTrackOption?.value}
                    onSelectTrack={(trackRank) =>
                      setSelectedTrackOption(
                        trackOptions.find(({ value: v }) => v === trackRank.spTrackId) || null
                      )
                    }
                  />
                ) : (
                  <Loading />
                )}
              </Box>
            </Box>
          )}
        </>
      )}
      {loading && <Loading />}
    </Grid>
  );
}
