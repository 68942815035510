import {
  Box,
  ClickAwayListener,
  Grid,
  Menu,
  MenuItem,
  MenuList,
  Typography,
  TypographyOwnProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { colors } from 'styles/colors';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrow_left.svg';
import { supportedLanguages } from '../../i18n/config';

interface IStyledLabel extends TypographyOwnProps {
  isDarkColors: boolean;
}

const StyledLabel = styled(({ isDarkColors, ...props }: IStyledLabel) => <Typography {...props} />)(
  ({ theme, isDarkColors }) => ({
    ...theme.fontStyles.small,
    padding: '8px 8px',
    width: '24px',
    textAlign: 'center',
    borderRadius: '2px',
    color: isDarkColors ? theme.colors.background.light.b4 : theme.colors.background.dark.b3,
    background: isDarkColors ? theme.colors.background.dark.b1 : theme.colors.background.light.b3,
  })
);

const StyledMenu = styled(Menu)(({ theme }) => ({
  padding: 0,
  top: 10,
  borderRadius: '4px',
  '& .MuiMenuItem-root': {
    ...theme.fontStyles.subtext,
    color: theme.colors.text.light.placeholder,
    padding: '16px',
    minWidth: '140px',
  },
}));

const StyledArrow = styled(ArrowLeft)(() => ({
  width: '16px',
  marginLeft: '5px',
  transition: 'all 300ms',
  '& path': {
    fill: 'none !important',
  },
}));

interface IProps extends WithTranslation {
  isDarkColors: boolean;
}

const Switcher: React.FC<IProps> = ({ isDarkColors, t, i18n }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClose = () => setOpen(false);
  const handleChange = (lang: string) => {
    i18n.changeLanguage(lang);
    setOpen(false);
  };
  return (
    <Box>
      <Grid
        onClick={(event) => {
          setOpen((prev) => !prev);
          setAnchorEl(event.currentTarget);
        }}
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          paddingLeft: {
            xs: '27px',
            md: '16px',
          },
        }}
      >
        <StyledLabel isDarkColors={isDarkColors}>{i18n.language.toUpperCase()}</StyledLabel>
        <StyledArrow
          sx={{
            transform: open ? 'rotate(90deg)' : 'rotate(-90deg)',
            '& path': {
              stroke: isDarkColors ? colors.main.white : colors.main.black,
            },
          }}
        />
      </Grid>
      <StyledMenu open={open} variant="selectedMenu" anchorEl={anchorEl}>
        <Grid container>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList sx={{ padding: 0 }}>
              {Object.entries(supportedLanguages).map(([languageKey, languageLabel]) => (
                <MenuItem
                  onClick={() => handleChange(languageKey)}
                  key={languageKey}
                  selected={languageKey == i18n.language}
                >
                  {languageLabel}
                </MenuItem>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Grid>
      </StyledMenu>
    </Box>
  );
};

export default withTranslation()(Switcher);
