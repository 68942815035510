import * as yup from 'yup';
import { isEmail, isPassword } from 'utils/validation-rules';
import { TFunction } from 'i18next';

export const SignInSchema = (t: TFunction) =>
  yup.object({
    email: yup.string().required(t('Field is required')).matches(isEmail, t('Email is incorrect')),
    password: yup
      .string()
      .required(t('Field is required'))
      .matches(isPassword, t('Password is incorrect')),
  });
