import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enGlobal from '../locales/en/global.json';
import ptGlobal from '../locales/pt/global.json';
import esGlobal from '../locales/es/global.json';

export const defaultNS = 'global';

export const supportedLanguages = {
  en: 'English',
  pt: 'Portuguese',
  es: 'Spanish',
};

export function getLangLabel(langKey: string): string {
  return langKey in supportedLanguages
    ? supportedLanguages[langKey as keyof typeof supportedLanguages]
    : '';
}

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    supportedLngs: Object.keys(supportedLanguages),
    debug: true,
    resources: {
      en: {
        global: enGlobal,
      },
      pt: {
        global: ptGlobal,
      },
      es: {
        global: esGlobal,
      },
    },
    defaultNS,
  });
