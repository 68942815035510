import { DataGrid, gridClasses, GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { numberToShorthand } from '../../../../utils/helpers';
import { Box } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface ITrackRank {
  artistName?: string;
  trackName?: string;
  spTrackId: string;
  spArtistId: string;
  isrc: string;
  rank: number;
  totalStreams: number;
  completedStreams: number;
  completionRatePct: number;
}

interface Props {
  trackRanks: ITrackRank[];
  totalCount: number;
  page: number;
  perPage: number;
  spTrackId?: string;
  onSelectTrack: (track: ITrackRank) => void;
  onPaginationChange: ({ page, perPage }: { page: number; perPage: number }) => void;
}

const CompletionRateLeaderBoard: React.FC<Props> = ({
  totalCount,
  trackRanks,
  page,
  perPage,
  spTrackId,
  onPaginationChange,
  onSelectTrack,
}) => {
  const { t } = useTranslation();
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: page - 1,
    pageSize: perPage,
  });

  const percentFormatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 1,
    minimumFractionDigits: 1,
  });

  const columns: GridColDef<ITrackRank[][number]>[] = [
    // { field: 'isrc', headerName: 'ISRC', width: 130 },
    {
      field: 'rank',
      headerName: '#',
      resizable: false,
      disableColumnMenu: true,
      filterable: false,
      flex: 0.2,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'trackName',
      headerName: t('Track Name'),
      resizable: false,
      disableColumnMenu: true,
      filterable: false,
      flex: 1,
    },
    {
      field: 'totalStreams',
      headerName: t('Total Streams'),
      resizable: false,
      disableColumnMenu: true,
      filterable: false,
      flex: 0.6,
      align: 'right',
      headerAlign: 'right',
      valueFormatter: (value) => numberToShorthand(value),
    },
    {
      field: 'completedStreams',
      headerName: t('Completed Streams'),
      resizable: false,
      disableColumnMenu: true,
      filterable: false,
      flex: 0.6,
      align: 'right',
      headerAlign: 'right',
      valueFormatter: (value) => numberToShorthand(value),
    },
    {
      field: 'completionRatePct',
      headerName: t('Completion Rate %'),
      resizable: false,
      disableColumnMenu: true,
      filterable: false,
      flex: 0.6,
      align: 'right',
      headerAlign: 'right',
      valueFormatter: (value) => percentFormatter.format(value),
    },
  ];

  return (
    <Box sx={{ height: 'none', width: '100%' }} paddingTop={3}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <DataGrid
          rows={trackRanks}
          getRowId={(row) => row.spTrackId}
          columns={columns}
          autosizeOptions={{
            columns: ['name', 'status', 'createdBy'],
            includeOutliers: true,
            includeHeaders: false,
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: 'rank', sort: 'asc' }],
            },
            pagination: {
              paginationModel,
            },
          }}
          pagination
          paginationMode="server"
          rowCount={totalCount}
          onPaginationModelChange={(model: GridPaginationModel) => {
            onPaginationChange({
              page: model.page + 1,
              perPage: model.pageSize,
            });
          }}
          rowSelectionModel={spTrackId ? [spTrackId] : []}
          // checkboxSelection
          disableRowSelectionOnClick
          disableColumnSelector
          onCellClick={({ row }) => onSelectTrack(row)}
          // hideFooter
          sx={{
            width: '100%',
            borderLeft: 'none',
            borderRight: 'none',
            borderBottom: 1,
            borderTop: 1,
            borderRadius: 0,
            margin: 0,
            padding: 0,
            // boxShadow: 2,
            // border: 2,
            // borderColor: 'primary.light',
            '& .MuiDataGrid-cell': {
              fontSize: {
                xs: '13px',
                md: '14px',
              },
            },
            '& .MuiDataGrid-selectedRowCount': {
              display: 'none',
            },
            '& .MuiDataGrid-columnHeader': {
              fontSize: {
                xs: '13px',
                md: '14px',
              },
            },
            '& .MuiDataGrid-cell[data-field=trackName]': {
              fontWeight: 'bold',
              cursor: 'pointer',
            },
            '& .MuiDataGrid-row:hover': {
              background: '#EBECF0',
            },
            '& .MuiDataGrid-row.Mui-selected': {
              background: '#E4DBFC',
            },
            '& .MuiDataGrid-row.Mui-selected:hover': {
              background: '#E4DBFC',
            },
            '& .Mui-selected:hover': {
              background: '#EBECF0',
            },
            [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
              outline: 'none',
            },
            [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
              outline: 'none',
            },
          }}
        />
      </div>
    </Box>
  );
};

export default CompletionRateLeaderBoard;
