import React, { useCallback, memo, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';
// mui components
import { Grid, Typography } from '@mui/material';
// layouts
import AuthLayout from 'layouts/auth-layout';
// components
import StyledInputContainer from '../components/styled-input-container';
import StyledButton from '../components/styled-button';
import StyledLink from '../components/styled-link';
import StyledText from '../components/styled-text';
// custom hooks
import { IPermissions, useUserData } from 'hooks/useUserData';
import { useError } from 'hooks/useError';
// controllers
import PasswordInputController from 'controllers/password-input-controller';
import BaseInputController from 'controllers/base-input-controller';
// validations
import { SignInSchema } from './validation';
// api
import api from 'utils/api';
// styles
import { fontStyles } from 'styles/font-styles';
import { colors } from 'styles/colors';
import StyledAnchorLink from '../components/styled-anchor-link';
import { useTranslation } from 'react-i18next';

const SignIn: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [isSubmitting, setSubmitting] = useState(false);

  const { message, setError } = useError();

  const { updateUserData, updatePermissionData } = useUserData();

  const form = useForm({ resolver: yupResolver(SignInSchema(t)) });

  const onSubmit = useCallback(
    async (formValues: any) => {
      try {
        setError(null);
        setSubmitting(true);
        await api.auth.signIn({ data: formValues });
        const data = await api.auth.getProfile();
        const completeUserData = { ...data, isAuthorized: true };
        updateUserData(completeUserData);

        const permissions: IPermissions = await api.auth.getPermissions();
        updatePermissionData(permissions);

        history.push('/deals');
      } catch (err) {
        setError(err);
      } finally {
        setSubmitting(false);
      }
    },
    [history, setError, updateUserData, updatePermissionData]
  );

  return (
    <FormProvider {...form}>
      <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
        <AuthLayout>
          <>
            <Grid
              container
              flexDirection="column"
              alignItems="center"
              sx={{ maxWidth: '500px', margin: '50px 0' }}
            >
              {/* <Box marginBottom={8} sx={{ width: '100%' }}>
                <AuthSwitcher active="sign-in" />
              </Box> */}
              <Typography sx={{ marginBottom: '5px', ...fontStyles.h3, color: colors.main.white }}>
                {t('Sign in to your account')}
              </Typography>
              <Typography
                sx={{
                  fontSize: '14px',
                  lineHeight: '20px',
                  color: colors.system.error,
                  height: '20px',
                  width: '100%',
                  textAlign: 'center',
                  marginBottom: '10px',
                }}
              >
                {message}
              </Typography>
              <StyledInputContainer>
                <BaseInputController
                  dark
                  withError
                  name="email"
                  type="email"
                  label={t('Email address')}
                />
              </StyledInputContainer>
              <Grid container flexDirection="column">
                <PasswordInputController
                  dark
                  withError
                  name="password"
                  label={t('Enter password')}
                />
              </Grid>
              <Grid container justifyContent="flex-end">
                <StyledLink to="/forgot-password" sx={{ transform: 'translateY(-11px)' }}>
                  {t('Forgot password?')}
                </StyledLink>
              </Grid>
              <StyledButton loading={isSubmitting} type="submit">
                {t('Continue')}
              </StyledButton>
              <Grid container justifyContent="center" alignItems="baseline">
                <StyledText>{t("Don't have account?")}</StyledText>{' '}
                <StyledAnchorLink href="https://snafurecords.com/contact">
                  {t('Contact Us')}
                </StyledAnchorLink>
              </Grid>
            </Grid>
          </>
        </AuthLayout>
      </form>
    </FormProvider>
  );
};

export default memo(SignIn);
