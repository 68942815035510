import { Box, CircularProgress, Grid, Tooltip } from '@mui/material';
import DialogModal from 'components/dialog-modal';
import PurpleButton from 'components/purple-button';
import Row from 'components/row';
import Text from 'components/text';
import { Info } from 'lucide-react';
import { useCallback, useState } from 'react';
import api from 'utils/api';
import { formatValueToDollars, getFormattedDate } from 'utils/helpers';
import { IRecoupment } from '../../../hooks/useUserData';
import { RecoupmentStatus } from '../sections/admin-section/Renewals';
import { StyledLabel, StyledValue } from '../sections/styled-components';
import { useTranslation } from 'react-i18next';

const Recoupment: React.FC<IRecoupment & { onUpdate?: (recoupment: IRecoupment) => void }> = ({
  onUpdate,
  ...recoupmentData
}) => {
  const { t } = useTranslation();
  const [confirmedRenewStatus, setConfirmedRenewStatus] = useState<
    'idle' | 'pending' | 'confirmed'
  >('idle');

  const [renewableDeal, setRenewableDeal] = useState<{ id: number; totalAdvance: number } | null>(
    null
  );

  const handleCloseModal = useCallback(() => {
    setRenewableDeal(null);
  }, []);

  const handleConfirmRenew = useCallback(() => {
    setConfirmedRenewStatus('pending');
    api.recoupments
      .statusUpdate({
        id: renewableDeal?.id,
        data: { status: RecoupmentStatus.APPROVED },
      })
      .then(() => {
        setConfirmedRenewStatus('confirmed');
        if (onUpdate) {
          onUpdate(recoupmentData);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [recoupmentData, renewableDeal, onUpdate]);

  return (
    <>
      <Grid container spacing={2} marginBottom={2}>
        <Grid item xs={12} md={6}>
          <Box
            bgcolor="#FAFBFC"
            border={1}
            borderColor="#eee"
            borderRadius={4}
            textAlign="center"
            padding={2}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap={1}
              marginTop={1}
              marginBottom={4}
            >
              <Text fontSize={20}>{t('Payment Breakdown')}</Text>
            </Box>
            <Box position="relative" display="inline-block" marginBottom={4}>
              <CircularProgress
                variant="determinate"
                className="progress-meter"
                sx={{
                  zIndex: 2,
                  position: 'relative',
                  color: '#1ACC81',
                }}
                size={220}
                thickness={10}
                value={
                  recoupmentData?.estimatedRemainingDuration < 1
                    ? 100
                    : recoupmentData
                    ? (recoupmentData.amountRecouped / recoupmentData.totalAdvance) * 100
                    : 0
                }
              />
              <CircularProgress
                variant="determinate"
                className="progress-meter"
                sx={{
                  left: 0,
                  position: 'absolute',
                  color: '#dde5ee',
                }}
                size={220}
                thickness={10}
                value={100}
              />
            </Box>
            <Box
              display="flex"
              gap={1}
              alignItems="center"
              justifyContent="center"
              marginBottom={2}
            >
              <Box width={18} height={18} borderRadius={9} bgcolor="#dde5ee" />
              <Text fontSize={16} color="#616978">
                {t('Total Advance')} {formatValueToDollars(recoupmentData?.totalAdvance || 0)}
              </Text>
            </Box>
            <Box
              display="flex"
              gap={1}
              alignItems="center"
              justifyContent="center"
              marginBottom={2}
            >
              <Box width={18} height={18} borderRadius={9} bgcolor="#1ACC81" />
              <Text fontSize={16} color="#616978">
                {t('Amount Recouped')} {formatValueToDollars(recoupmentData?.amountRecouped || 0)}
              </Text>
            </Box>
            <Box paddingX={4} paddingY={2}>
              <Text fontSize={18} color="#333" display="inline">
                {recoupmentData?.estimatedRemainingDuration < 1
                  ? t('You have recouped!')
                  : t('you_recoup_in_months', {
                      count: recoupmentData?.estimatedRemainingDuration,
                    })}
              </Text>
              <Box
                component={'span'}
                display="inline-block"
                width={10}
                height={10}
                marginLeft={0.6}
                position="relative"
              >
                <Box component="span" position="absolute" top={-3} left={0}>
                  <Tooltip
                    title={t('This calculation is based on historical payment averages')}
                    arrow
                  >
                    <Info color="#888" size={18} />
                  </Tooltip>
                </Box>
              </Box>
            </Box>
          </Box>

          {recoupmentData.status === 'pending' && (
            <Box
              bgcolor="#FAFBFC"
              border={1}
              borderColor="#eee"
              borderRadius={4}
              textAlign="center"
              padding={2}
              marginTop={2}
            >
              <Box paddingBottom={2}>
                <Text fontSize={19}>
                  {t('You qualify for {{amount}} advance!', {
                    amount: formatValueToDollars(recoupmentData.totalAdvance * 1.35),
                  })}
                </Text>
              </Box>
              <PurpleButton
                onClick={(e) => {
                  e.preventDefault();
                  setRenewableDeal({
                    id: recoupmentData.id,
                    totalAdvance: recoupmentData.totalAdvance,
                  });
                }}
                sx={{ width: '70%' }}
              >
                <Text color="#fff" fontWeight="bold">
                  {t('Renew My Deal')}
                </Text>
              </PurpleButton>
            </Box>
          )}

          {recoupmentData.status === 'approved' && (
            <Box
              bgcolor="#FAFBFC"
              border={1}
              borderColor="#eee"
              borderRadius={4}
              textAlign="center"
              padding={2}
              marginTop={2}
            >
              <Box>
                <Text fontSize={19}>
                  {t('Deal renewal request submitted! We will contact you shortly.')}
                </Text>
              </Box>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={6} alignItems="center">
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            paddingTop={1}
            height="100%"
            justifyContent="center"
          >
            <Box>
              <StyledLabel>{t('Total Advance')}:</StyledLabel>
              <StyledValue paddingRight="12px">
                <Text component="span">
                  {formatValueToDollars(recoupmentData?.totalAdvance || 0)}
                </Text>
              </StyledValue>
            </Box>
            <Box borderBottom={1} borderColor="#dde5ee" />

            <Box>
              <StyledLabel>{t('Amount Recouped')}:</StyledLabel>
              <StyledValue paddingRight="12px">
                <Text component="span">
                  {formatValueToDollars(recoupmentData?.amountRecouped || 0)}
                </Text>
              </StyledValue>
            </Box>
            <Box borderBottom={1} borderColor="#dde5ee" />

            <Box>
              <StyledLabel>{t('Term Length')}:</StyledLabel>
              <StyledValue paddingRight="12px">
                <Text component="span">
                  {t('years_count', { count: recoupmentData?.termLength || 0 })}
                </Text>
              </StyledValue>
            </Box>
            <Box borderBottom={1} borderColor="#dde5ee" />

            <Box>
              <StyledLabel>{t('Recoupment Rate')}:</StyledLabel>
              <StyledValue paddingRight="12px">
                <Text component="span">{recoupmentData?.recoupmentRate || 0.0}</Text>
              </StyledValue>
            </Box>
            <Box borderBottom={1} borderColor="#dde5ee" />

            <Box>
              <StyledLabel>{t('Contract Date')}:</StyledLabel>
              <StyledValue paddingRight="12px">
                <Text component="span">
                  {getFormattedDate(recoupmentData?.contractDate) || '–'}
                </Text>
              </StyledValue>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <DialogModal withActions={false} open={renewableDeal !== null} handleClose={handleCloseModal}>
        {confirmedRenewStatus === 'idle' && (
          <>
            <Box paddingBottom={3}>
              <Text fontSize={26}>{t('Contract Date')}</Text>
            </Box>

            <Box>
              <Row
                title={
                  <Text fontSize={20} lineHeight={1.3}>
                    {t('Eligible Advance')}
                  </Text>
                }
                value={
                  <Text fontSize={20} lineHeight={1.3}>
                    {formatValueToDollars((renewableDeal?.totalAdvance || 0) * 1.35)}
                  </Text>
                }
              />
              <Row
                title={
                  <Text fontSize={20} lineHeight={1.3}>
                    {t('Eligible Term Length')}
                  </Text>
                }
                value={
                  <Text fontSize={20} lineHeight={1.3}>
                    {t('years_count', { count: 3 })}
                  </Text>
                }
              />
              <Box paddingTop={2}>
                <Text fontSize={20} lineHeight={1.3}>
                  {t(
                    'Please confirm if you would like to proceed by clicking the confirm button below.'
                  )}
                </Text>
              </Box>
              <Box paddingTop={4}>
                <PurpleButton onClick={handleConfirmRenew}>
                  <Text color="#fff" fontWeight="bold">
                    {t('Confirm')}
                  </Text>
                </PurpleButton>
              </Box>
            </Box>
          </>
        )}

        {confirmedRenewStatus === 'pending' && (
          <>
            <Box paddingBottom={3}>
              <Text fontSize={26}>{t('Deal Renew Offer')}</Text>
            </Box>

            <Box>
              <Row
                title={
                  <Text fontSize={20} lineHeight={1.3}>
                    {t('Eligible Advance')}
                  </Text>
                }
                value={
                  <Text fontSize={20} lineHeight={1.3}>
                    {formatValueToDollars((renewableDeal?.totalAdvance || 0) * 1.35)}
                  </Text>
                }
              />
              <Row
                title={
                  <Text fontSize={20} lineHeight={1.3}>
                    {t('Eligible Term Length')}
                  </Text>
                }
                value={
                  <Text fontSize={20} lineHeight={1.3}>
                    {t('years_count', { count: 3 })}
                  </Text>
                }
              />
              <Box paddingTop={2}>
                <Text fontSize={20} lineHeight={1.3}>
                  {t(
                    'Please confirm if you would like to proceed by clicking the confirm button below.'
                  )}
                </Text>
              </Box>
              <Box paddingTop={4}>
                <PurpleButton disabled={true}>
                  <Text color="#fff" fontWeight="bold">
                    {t('Please wait...')}
                  </Text>
                </PurpleButton>
              </Box>
            </Box>
          </>
        )}

        {confirmedRenewStatus === 'confirmed' && (
          <>
            <Box paddingBottom={3}>
              <Text fontSize={26}>{t('Renew Request Submitted')}</Text>
            </Box>

            <Box>
              <Text fontSize={20} lineHeight={1.3}>
                {t(
                  'Thank you for your interest! One of our A&R representatives will contact you shortly  with more details.'
                )}
              </Text>
            </Box>
          </>
        )}
      </DialogModal>
    </>
  );
};

export default Recoupment;
