import Text from 'components/text';
import SocialMediaCard from './SocialMediaCard';
import { useState, useRef, useEffect } from 'react';
import { Grid, Box, MenuItem } from '@mui/material';
import BaseInput from 'components/inputs/base-input';
import SocialAnalyticsTimeline from './SocialAnalyticsTimeline';
import SocialMediaAudienceByAge from './SocialMediaAudienceByAge';
import SocialMediaAudiencePieChart from './SocialMediaAudiencePieChart';
import AutocompleteInput, { IOption } from 'components/inputs/autocomplete-input';

import api from 'utils/api';
import { IArtistProfile } from './FugaAnalytics';

import { useUserData } from 'hooks/useUserData';
import { InvitationInterface } from '../admin-section/invited-list';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

interface ChartDataPoint {
  x: string;
  y: number;
}

interface RecentValue {
  x: string;
  y: number;
  wd: number;
  wdp: number;
}

interface ChartData {
  data: ChartDataPoint[];
}

export interface Metric {
  chartData: ChartData[];
  recentValue: RecentValue;
}

interface SocialMediaMetrics {
  spotifyPopularity: Metric;
  spotifyListeners: Metric;
  spotifyFollowers: Metric;
  instagramFollowers: Metric;
  tiktokLikes: Metric;
  tiktokFollowers: Metric;
  youtubeViews: Metric;
  youtubeSubscribers: Metric;
}

// -- audience interface
export interface AudienceByAgeGroup {
  label: string;
  percentage: number;
  malePercentage: number;
  femalePercentage: number;
}

interface OverallPercentage {
  overallMalePercentage: number;
  overallFemalePercentage: number;
}

interface SocialMediaAudienceReport {
  audiencesByAgeGroup: AudienceByAgeGroup[];
  overallPercentage: OverallPercentage;
}

interface SocialMediaAudienceReportGroup {
  youtube: SocialMediaAudienceReport | null;
  tiktok: SocialMediaAudienceReport | null;
  instagram: SocialMediaAudienceReport | null;
}

interface OverallGenderData {
  label: string;
  value: number;
  color: string;
}

const generateOverallGenderData = (
  t: TFunction,
  {
    male,
    female,
  }: {
    male: number;
    female: number;
  }
): OverallGenderData[] => {
  return [
    { label: t('Male'), value: male, color: '#f96565' },
    { label: t('Female'), value: female, color: '#fba5a5' },
  ];
};

function removeDuplicates(items: IArtistProfile[]): IArtistProfile[] {
  const seenIds = new Set<string>();
  const uniqueItems: IArtistProfile[] = [];

  for (const item of items) {
    if (!seenIds.has(item.artistSpotifyId)) {
      seenIds.add(item.artistSpotifyId);
      uniqueItems.push(item);
    }
  }

  return uniqueItems;
}

function getSelectedTimelineLabel(t: TFunction, selected: string): string {
  switch (selected) {
    case '1W':
      return t('Last Week');
    case '1M':
      return t('Last Month');
    case '2M':
      return t('Last Two Months');
    case '3M':
      return t('Last Three Months');
    case '6M':
      return t('Last Six Months');
    case '1Y':
      return t('Last Year');
    default:
      return t('Last Six Months');
  }
}

const DEFAULT_AUDIENCE_GROUP: SocialMediaAudienceReportGroup = {
  youtube: null,
  tiktok: null,
  instagram: null,
};

export default function SocialAnalytics() {
  const { t } = useTranslation();
  const { permissionsData } = useUserData();

  const elementRef = useRef(null);
  const [loading, setLoading] = useState(true);

  const [artistProfiles, setArtistProfiles] = useState<IArtistProfile[]>([]);
  const [selectedSpProfile, setSelectedSpProfile] = useState<string | null>(null);

  const [artistOptions, setArtistOptions] = useState<IOption[]>([]);
  const [selectedArtistOption, setSelectedArtistOption] = useState<IOption | null>(null);

  const [selectedDate, setSelectedDate] = useState('3M');
  const [filterPlatform, setFilterPlatform] = useState<string>('');

  const [socialPlatformEngagements, setSocialPlatformEngagements] =
    useState<SocialMediaMetrics | null>(null);

  const [socialPlatformAudiences, setSocialPlatformAudiences] =
    useState<SocialMediaAudienceReportGroup>(DEFAULT_AUDIENCE_GROUP);
  const [showSocialPlatformAudiences, setShowSocialPlatformAudiences] = useState(false);

  // const elementSize = useWatchElementSizeByRef(elementRef, { debounceTime: 300 });

  useEffect(() => {
    if (artistProfiles) {
      const options = artistProfiles.map((profile) => ({
        value: profile.artistSpotifyId,
        label: profile.artistName,
      }));
      setArtistOptions(options);
    }
  }, [artistProfiles]);

  useEffect(() => {
    const option = artistOptions.find((opt) => opt.value === selectedSpProfile) || null;
    setSelectedArtistOption(option);
  }, [artistOptions, selectedSpProfile]);

  useEffect(() => {
    // setLoading(true);
    let isMounted = true;
    let firstProfile: IArtistProfile | null = null;

    (async () => {
      // Collect artist spotify id.
      if (permissionsData.admin) {
        const invitedArtists = await api.invitation.getInvitedArtists();
        const formattedInvitedArtists: IArtistProfile[] = invitedArtists.map(
          (artist: InvitationInterface) => ({
            artistName: artist.artistname,
            artistSpotifyId: artist.spotifyid,
          })
        );
        setArtistProfiles(removeDuplicates(formattedInvitedArtists));
        setLoading(false);
      } else {
        try {
          const profiles: IArtistProfile[] = await api.analytics.getArtistProfiles();
          if (isMounted) {
            setArtistProfiles(profiles);
            [firstProfile] = profiles;
            setSelectedSpProfile(firstProfile.artistSpotifyId);
            setLoading(false);
          }
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [permissionsData]);

  useEffect(() => {
    setSocialPlatformEngagements(null);
    setShowSocialPlatformAudiences(false);
    let isMounted = true;

    (async () => {
      // Collect artist engagement reports.
      if (selectedSpProfile && selectedDate) {
        try {
          setLoading(true);
          const res: SocialMediaMetrics = await api.analytics.socialPlatformEngagements({
            params: {
              spProfile: selectedSpProfile,
              dateRange: selectedDate,
            },
          });
          if (isMounted) {
            if (res.hasOwnProperty('length')) {
              setSocialPlatformEngagements(null);
            } else {
              setSocialPlatformEngagements(res);
            }
            setShowSocialPlatformAudiences(true);
            setLoading(false);
          }
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [selectedSpProfile, selectedDate]);

  useEffect(() => {
    let isMounted = true;

    async function getAudienceData(platform: keyof SocialMediaAudienceReportGroup) {
      try {
        const audiences: SocialMediaAudienceReport = await api.analytics.socialPlatformAudiences({
          params: {
            spProfile: selectedSpProfile,
            platform,
          },
        });

        return !audiences.hasOwnProperty('length') ? audiences : null;
      } catch (error) {
        console.error(error);
      }
    }

    (async () => {
      if (selectedSpProfile) {
        setSocialPlatformAudiences(() => {
          setFilterPlatform('');
          setShowSocialPlatformAudiences(false);

          return DEFAULT_AUDIENCE_GROUP;
        });

        const tiktokAudience = await getAudienceData('tiktok');
        const instagramAudience = await getAudienceData('instagram');
        const youtubeAudience = await getAudienceData('youtube');

        if (isMounted) {
          setSocialPlatformAudiences({
            tiktok: tiktokAudience || null,
            instagram: instagramAudience || null,
            youtube: youtubeAudience || null,
          });

          if (youtubeAudience) {
            setFilterPlatform('youtube');
          } else if (instagramAudience) {
            setFilterPlatform('instagram');
          } else if (tiktokAudience) {
            setFilterPlatform('tiktok');
          }

          setShowSocialPlatformAudiences(true);
        }
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [selectedSpProfile]);

  const selectedAudienceData =
    socialPlatformAudiences[filterPlatform as keyof SocialMediaAudienceReportGroup] || null;

  if (loading) {
    return (
      <Box padding={6} textAlign="center">
        <Text fontSize="20px">{t('Please wait...')}</Text>
      </Box>
    );
  }

  if ((!socialPlatformEngagements || !artistProfiles.length) && !permissionsData.admin) {
    return (
      <Box padding={6} textAlign="center">
        <Text fontSize="20px">{t('No data available')}</Text>
      </Box>
    );
  }

  const chartDataExists = (data: ChartData[]) => {
    const [axis] = data;

    return axis.data.length > 0;
  };

  return (
    <Box display="flex" flexDirection="column" gap={2} overflow="hidden" paddingBottom={8}>
      {permissionsData.admin && (
        <Box padding={2} paddingBottom={0}>
          <Box paddingBottom={2}>
            <Text>{t('Admin Artist Selection')}:</Text>
          </Box>
          <Box flex={1}>
            <AutocompleteInput
              matchFrom="any"
              size="small"
              dark={false}
              disabled={loading}
              name="spotifyId"
              error={loading ? false : selectedSpProfile === null}
              onChange={(value) => {
                setSelectedSpProfile(value);
              }}
              disableClearable={true}
              value={selectedArtistOption}
              options={artistOptions}
              label={t('Select Artist')}
              selectOnFocus
            />
          </Box>
        </Box>
      )}

      {!socialPlatformEngagements && selectedArtistOption !== null && (
        <Box>
          <Box minWidth="600px" marginX="auto">
            <SocialAnalyticsTimeline selected={selectedDate} setSelectedDate={setSelectedDate} />
          </Box>
          <Box padding={2}>
            {t('No data available for')} {getSelectedTimelineLabel(t, selectedDate).toLowerCase()}
          </Box>
        </Box>
      )}

      {socialPlatformEngagements && (
        <>
          <Box
            maxWidth="inherit"
            overflow="auto"
            sx={{
              maxWidth: '100vw',
            }}
          >
            <Box minWidth="600px" marginX="auto">
              <SocialAnalyticsTimeline selected={selectedDate} setSelectedDate={setSelectedDate} />
            </Box>
          </Box>

          <Box paddingX={2} paddingBottom={1}>
            <Text fontSize={20}>{t('Engagement Report')}</Text>
          </Box>

          <Grid container spacing={2} paddingX={2}>
            {chartDataExists(socialPlatformEngagements.spotifyPopularity.chartData) && (
              <Grid item xs={12} md={6} ref={elementRef}>
                <SocialMediaCard
                  label={t('Spotify Popularity')}
                  icon="spotify"
                  data={socialPlatformEngagements.spotifyPopularity}
                />
              </Grid>
            )}

            {chartDataExists(socialPlatformEngagements.spotifyListeners.chartData) && (
              <Grid item xs={12} md={6}>
                <SocialMediaCard
                  label={t('Spotify Listeners')}
                  icon="spotify"
                  data={socialPlatformEngagements.spotifyListeners}
                />
              </Grid>
            )}
          </Grid>
          <Grid container spacing={2} paddingX={2}>
            {chartDataExists(socialPlatformEngagements.spotifyFollowers.chartData) && (
              <Grid item xs={12} md={6}>
                <SocialMediaCard
                  label={t('Spotify Followers')}
                  icon="spotify"
                  data={socialPlatformEngagements.spotifyFollowers}
                />
              </Grid>
            )}

            {chartDataExists(socialPlatformEngagements.instagramFollowers.chartData) && (
              <Grid item xs={12} md={6}>
                <SocialMediaCard
                  label={t('Instagram Followers')}
                  icon="instagram"
                  data={socialPlatformEngagements.instagramFollowers}
                />
              </Grid>
            )}
          </Grid>

          <Grid container spacing={2} paddingX={2}>
            {chartDataExists(socialPlatformEngagements.tiktokLikes.chartData) && (
              <Grid item xs={12} md={6}>
                <SocialMediaCard
                  label={t('TikTok Likes')}
                  icon="tiktok"
                  data={socialPlatformEngagements.tiktokLikes}
                />
              </Grid>
            )}

            {chartDataExists(socialPlatformEngagements.tiktokFollowers.chartData) && (
              <Grid item xs={12} md={6}>
                <SocialMediaCard
                  label={t('TikTok Followers')}
                  icon="tiktok"
                  data={socialPlatformEngagements.tiktokFollowers}
                />
              </Grid>
            )}
          </Grid>

          <Grid container spacing={2} paddingX={2}>
            {chartDataExists(socialPlatformEngagements.youtubeViews.chartData) && (
              <Grid item xs={12} md={6}>
                <SocialMediaCard
                  label={t('YouTube Views')}
                  icon="youtube"
                  data={socialPlatformEngagements.youtubeViews}
                />
              </Grid>
            )}

            {chartDataExists(socialPlatformEngagements.youtubeSubscribers.chartData) && (
              <Grid item xs={12} md={6}>
                <SocialMediaCard
                  label={t('YouTube Subscribers')}
                  icon="youtube"
                  data={socialPlatformEngagements.youtubeSubscribers}
                />
              </Grid>
            )}
          </Grid>
        </>
      )}

      {showSocialPlatformAudiences && (
        <>
          <Box
            paddingX={2}
            paddingTop={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Text fontSize={20}>{t('Audience Demographics')}</Text>
            </Box>
            <Box>
              <BaseInput
                disabled={loading}
                size="small"
                select
                variant="filled"
                name="distributor"
                label={t('Platform')}
                value={filterPlatform}
                SelectProps={{ displayEmpty: true }}
                onChange={(event: any) => {
                  setFilterPlatform(event.target.value);
                }}
                error={false}
                sx={{ width: '200px' }}
              >
                <MenuItem value="youtube">YouTube</MenuItem>
                <MenuItem value="tiktok">TikTok</MenuItem>
                <MenuItem value="instagram">Instagram</MenuItem>
              </BaseInput>
            </Box>
          </Box>

          {selectedAudienceData ? (
            <Box paddingX={2}>
              <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap={2}>
                <Box display="flex" alignContent="center" justifyContent="center" paddingBottom={4}>
                  <Box border={1} borderColor="#ddd" padding={2} borderRadius={2}>
                    {selectedAudienceData && (
                      <SocialMediaAudiencePieChart
                        width={280}
                        height={280}
                        data={generateOverallGenderData(t, {
                          male: selectedAudienceData.overallPercentage.overallMalePercentage,
                          female: selectedAudienceData.overallPercentage.overallFemalePercentage,
                        })}
                      />
                    )}
                  </Box>
                </Box>
                <Box display="flex" width="100%">
                  <Box width="100%">
                    {selectedAudienceData && (
                      <SocialMediaAudienceByAge data={selectedAudienceData.audiencesByAgeGroup} />
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box padding={2}>
              {filterPlatform
                ? t('No {{filterPlatform}} data available', { filterPlatform })
                : t('No data available')}
            </Box>
          )}
        </>
      )}
    </Box>
  );
}
