import { Box, Button, Grid, Typography } from '@mui/material';
import Text from 'components/text';
import React from 'react';
import { fontStyles } from 'styles/font-styles';
import { colors } from 'styles/colors';
import StyledButton from '../../../auth/components/styled-button';
import { useState } from 'react';
import NewReferralForm from './new-referral-form';
import { theme } from '../../../../styles/theme';
import Calculator from '../../components/referrals/calculator';
import { useTranslation } from 'react-i18next';

export interface IHowTo {
  title: string;
  children: string | React.ReactNode;
}

const HowToCard = ({ title, children }: { title: string; children: React.ReactNode }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      padding={2}
      sx={{ backgroundColor: '#E4DBFC', borderRadius: '1rem' }}
    >
      <Text fontSize={18} lineHeight={1.5} fontWeight="bold">
        {title}
      </Text>
      <Text fontSize={18} lineHeight={1.5} fontStyle="italic">
        {children}
      </Text>
    </Box>
  );
};

export enum ReferralFlowState {
  introduction,
  form,
  confirmation,
}

const Referral: React.FC = () => {
  const { t } = useTranslation();
  const [flowState, setFlowState] = useState<ReferralFlowState>(ReferralFlowState.introduction);

  return (
    <Grid container flexDirection="column">
      {flowState === ReferralFlowState.introduction && (
        <>
          <Box padding={4} paddingBottom={2}>
            <Text sx={{ ...fontStyles.h3, color: colors.text.light.title }} paddingBottom={1.5}>
              {t('Referral Program')}
            </Text>

            <Text fontSize={18} lineHeight={1.5} paddingBottom={1.5}>
              {t('referral_program_description')}
            </Text>
          </Box>

          <Box display="flex" flexDirection="column" alignItems="center" paddingTop={2}>
            <StyledButton
              variant="contained"
              type="button"
              sx={{ width: '300px' }}
              onClick={() => setFlowState(ReferralFlowState.form)}
            >
              {t('Submit a Referral Form')}
            </StyledButton>
          </Box>

          <Box display="flex" flexDirection="column" gap={4} padding={4} paddingTop={0}>
            <Typography sx={{ ...fontStyles.h4, color: colors.text.light.title }}>
              {t('How It Works')}
            </Typography>
            <Box display="flex" flexDirection="column" gap={2}>
              <HowToCard title={`1. ${t('Chat with your friends')}`}>
                {t('Share your experience with SNAFU with your friends and collaborators.')}
              </HowToCard>
              <HowToCard title={`2. ${t('Submit a Referral Form')}`}>
                {t(
                  'Login to your SNAFU Artist Dashboard. Select “Referral” on the left and fill out the referral form.'
                )}
              </HowToCard>
              <HowToCard title={`3. ${t('Get Rewarded')}`}>
                {t(
                  'For every successful artist funded, earn 3% commission on the total advance amount signed.'
                )}
              </HowToCard>
            </Box>
          </Box>

          <Box display="flex" flexDirection="column" gap={4} padding={4} paddingTop={0}>
            <Box display="flex" flexDirection="column">
              <Calculator />
            </Box>
          </Box>

          <Box display="flex" flexDirection="column" gap={4} padding={4} paddingTop={0}>
            <Typography sx={{ ...fontStyles.h4, color: colors.text.light.title }}>
              {t('Eligibility Criteria')}
            </Typography>
            <Box display="flex" flexDirection="column">
              {t('In order to be eligible for funding with SNAFU, all referred artists must:')}
              <ul>
                <li>
                  {t(
                    'Have awesome music - we love all styles of music, so there are no specific genres required.'
                  )}
                </li>
                <li>
                  {t(
                    'Own a percentage stake in their masters, and have catalog that is at least six months old.'
                  )}
                </li>
                <li>
                  {t('Earn a minimum of $5,000 per annum on streaming and publishing royalties.')}
                </li>
                <li>{t('Be excited to work with us to grow their catalog!')}</li>
              </ul>
              <div>
                {t('referral_contact_email')}{' '}
                <Text
                  component="a"
                  href="mailto:sandra.woo@snafurecords.com"
                  color="#000"
                  fontSize="16px"
                  sx={{
                    textDecoration: 'none',
                  }}
                >
                  sandra.woo@snafurecords.com
                </Text>
              </div>
            </Box>
          </Box>

          <Box padding={4} paddingTop={0}>
            <Typography sx={{ ...fontStyles.h4, color: colors.text.light.title }}>
              {t('Ready to Refer?')}
            </Typography>
            <Box display="flex" flexDirection="column" alignItems="center" paddingTop={2}>
              <StyledButton
                variant="contained"
                type="button"
                sx={{ width: '300px' }}
                onClick={() => setFlowState(ReferralFlowState.form)}
              >
                {t('Submit a Referral Form')}
              </StyledButton>
            </Box>
          </Box>
        </>
      )}

      {flowState === ReferralFlowState.form && (
        <Box padding={4} maxWidth={'600px'}>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Typography sx={{ ...fontStyles.h3, color: colors.text.light.title }}>
              {t('Refer an Artist')}
            </Typography>
            <Button
              variant="text"
              onClick={() => setFlowState(ReferralFlowState.introduction)}
              sx={{
                ...theme.fontStyles.subtext,
                color: theme.colors.text.light.placeholder,
                textTransform: 'none',
              }}
            >
              {t('Back')}
            </Button>
          </Box>
          <Box paddingTop={4}>
            <NewReferralForm onSuccess={() => setFlowState(ReferralFlowState.confirmation)} />
          </Box>
        </Box>
      )}
      {flowState === ReferralFlowState.confirmation && (
        <Box padding={4} maxWidth={'600px'}>
          <Typography sx={{ ...fontStyles.h3, color: colors.text.light.title }}>
            {t('Thank you for your referral!')}
          </Typography>
          <Text color="#666" fontSize={18} paddingTop={2} lineHeight={1.5}>
            {t('We have received your referral and will reach out to the artist shortly.')}
          </Text>
        </Box>
      )}
    </Grid>
  );
};

export default Referral;
