import React, { FC } from 'react';
// mui components
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  styled,
  DialogProps,
} from '@mui/material';
// icons
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
// styles
import { colors } from 'styles/colors';
import { WithTranslation, withTranslation } from 'react-i18next';

interface IStyledDialog extends DialogProps {
  dark?: boolean;
  customWidth?: string;
}

const StyledDialog = styled((props: IStyledDialog) => <Dialog {...props} />)(
  ({ theme, dark, customWidth }) => ({
    '& .MuiDialogContent-root': {
      margin: '10px 0 24px',
      padding: 0,
    },
    '& .MuiDialogActions-root': {
      justifyContent: 'center',
      height: '48px',
      padding: 0,
      '& button': {
        margin: '0 12px',
      },
    },
    '& .MuiDialog-paper': {
      width: customWidth || '480px',
      padding: '24px',
      borderRadius: '8px',
      overflow: 'visible',
      backgroundColor: dark ? theme.colors.main.blackLight : theme.colors.main.white,
    },
  })
);

interface DialogTitleProps {
  id: string;
  dark?: boolean;
  children?: React.ReactNode;
  onClose: () => void;
}

const StyledDialogTitle: React.FC<DialogTitleProps> = (props) => {
  const { dark, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ display: 'flex', justifyContent: 'flex-end', height: '40px', padding: 0 }}
      {...other}
    >
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            width: '40px',
            height: '40px',
            '& path': {
              fill: dark ? colors.main.white : colors.main.blackLight,
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const StyledButton = styled(Button)({
  height: '48px',
  width: '126px',
  textTransform: 'unset',
  boxShadow: 'none',
});

interface IProps {
  open: boolean;
  withActions: boolean;
  dark?: boolean;
  maxWidth?: string;
  handleClose: () => void;
  children: React.ReactNode;
}

const DialogModal: FC<IProps & WithTranslation> = ({
  t,
  open,
  dark,
  withActions,
  maxWidth,
  handleClose,
  children,
}) => {
  return (
    <StyledDialog
      open={open}
      dark={dark}
      customWidth={maxWidth}
      aria-labelledby="customized-dialog-title"
      onClose={handleClose}
    >
      <StyledDialogTitle id="customized-dialog-title" dark={dark} onClose={handleClose} />
      <DialogContent>{children}</DialogContent>
      {withActions && (
        <DialogActions>
          <StyledButton
            variant="contained"
            sx={{
              backgroundColor: colors.main.purple,
              '&:hover': {
                backgroundColor: colors.main.purpleDark,
              },
            }}
            onClick={handleClose}
          >
            {t('Close')}
          </StyledButton>
        </DialogActions>
      )}
    </StyledDialog>
  );
};

export default withTranslation()(DialogModal);
