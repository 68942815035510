import * as d3 from 'd3';
import { ceilTo, floorTo, formatTimeDuration, numberToShorthand } from 'utils/helpers';

import { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export interface DataPoint {
  x: number;
  y: number;
}

export interface DataPointWithExtra extends DataPoint {
  completionRate: number;
  remainingStreams: number;
  streamPctLength?: number;
  numberOfStreams?: number;
  cumulativeStreams?: number;
  totalStreamsPerTrack?: number;
  normalizedCumulativeStreams?: number;
  reverseCumulativeStreams?: number;
}

export type CompletionRateData = {
  track: DataPointWithExtra[];
  artist: DataPointWithExtra[];
  snafu: DataPointWithExtra[];
};

export default function CompletionRateChart({
  data,
  legend,
}: {
  data: CompletionRateData;
  legend: Legend;
}) {
  // if (data.length > 0) {
  //   const [first] = data;

  return <LinesChart data={data} height={400} legend={legend} curveFactor={0.9} animate={false} />;
}

export interface LegendItem {
  key: string;
  label: string;
  color: string;
  invertColor: string;
  enabled: boolean;
  value?: number;
}

export type Legend = LegendItem[];

interface LineChartProps {
  data: Record<string, DataPointWithExtra[]>;
  width?: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  legend?: Legend;
  curveFactor?: number;
  animate?: boolean;
}

const LinesChart: React.FC<LineChartProps> = ({
  data,
  width: initialWidth = 800,
  height: initialHeight = 800,
  margin = { top: 5, right: 15, bottom: 50, left: 60 },
  legend = [],
  curveFactor = 0.5,
  animate = false,
}) => {
  const { t } = useTranslation();
  const svgRef = useRef<SVGSVGElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(initialWidth);
  const [height, setHeight] = useState(initialHeight);

  const legendByKeys = legend.reduce((acc, item) => {
    acc[item.key] = item;
    return acc;
  }, {} as Record<string, LegendItem>);

  useEffect(() => {
    const handleResize = () => {
      if (svgRef.current) {
        const parentEl = svgRef.current.parentElement?.getBoundingClientRect() || {
          width: initialWidth,
        };
        setWidth(parentEl.width);
        setHeight(parentEl.width > 400 ? parentEl.width / 3 : parentEl.width / 2);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [initialWidth]);

  useEffect(() => {
    if (!svgRef.current || !tooltipRef.current) return;

    const svg = d3.select(svgRef.current).style('font-size', '16px').style('fill', '#555555');
    svg.selectAll('*').remove();

    const chartWidth = width - margin.left - margin.right;
    const chartHeight = height - margin.top - margin.bottom;

    const minY = floorTo(
      Math.min(
        ...Object.values(data)
          .flat()
          .map((d) => d.y)
      ) - 2,
      5
    );

    const maxX = ceilTo(
      Math.max(
        ...Object.values(data)
          .flat()
          .map((d) => d.x)
      ),
      5
    );

    const xScale = d3.scaleLinear().nice().domain([0, maxX]).range([0, chartWidth]);

    const yScale = d3.scaleLinear().nice().domain([minY, 100]).range([chartHeight, 0]);

    const line = d3
      .line<DataPointWithExtra>()
      .x((d) => xScale(d.x))
      .y((d) => yScale(d.y))
      .curve(d3.curveBasis);

    const g = svg.append('g').attr('transform', `translate(${margin.left},${margin.top})`);

    // append axis labels
    svg
      .append('text')
      .attr('class', 'x label')
      .attr('text-anchor', 'end')
      .attr('x', width)
      .attr('y', height - 5)
      .text(t('seconds'));

    svg
      .append('text')
      .attr('class', 'y label')
      // .attr('text-anchor', 'end')
      .attr('x', 5)
      .attr('y', 0)
      .attr('dy', '.75em')
      // .attr('transform', 'rotate(-90)')
      .text('%');

    // Add horizontal grid lines
    g.append('g')
      .attr('class', 'grid')
      .call(
        d3
          .axisLeft(yScale)
          .tickSize(-chartWidth)
          .ticks(5)
          .tickFormat(() => '')
      )
      .call((gTickLine) =>
        gTickLine.selectAll('.tick line').attr('stroke', '#DFE1E6').attr('stroke-dasharray', '2,4')
      )
      .call((gDomain) => gDomain.select('.domain').remove());

    g.append('g')
      .attr('class', 'grid')
      .call(
        d3
          .axisBottom(xScale)
          .ticks(maxX / 30 + 1)
          .tickSize(chartHeight)
          .tickValues([0, maxX / 2, maxX])
          .tickFormat(() => '')
      )
      .call((gTickLine) =>
        gTickLine.selectAll('.tick line').attr('stroke', '#DFE1E6').attr('stroke-dasharray', '2,4')
      )
      .call((gDomain) => gDomain.select('.domain').remove());

    g.append('g')
      .attr('class', 'x-axis')
      .style('font-size', '12px')
      .style('color', '#555555')
      .attr('transform', `translate(0,${chartHeight})`)
      .call(
        d3
          .axisBottom(xScale)
          .tickFormat((d) => formatTimeDuration(Number(d)))
          .tickValues([0, maxX / 2, maxX])
      );

    // Add y-axis
    g.append('g')
      .attr('class', 'y-axis')
      .style('font-size', '12px')
      .style('color', '#555555')
      .call(
        d3
          .axisLeft(yScale)
          .tickFormat((d) => numberToShorthand(Number(d)))
          .ticks(5)
      );

    const keys = Object.keys(data);
    let paths = [];
    // Add the line path
    for (const index in keys) {
      const key = keys[index];
      const legendItem = legendByKeys[key];
      if (legendItem.enabled) {
        const path = g
          .append('path')
          .datum(data[key])
          .attr('fill', 'none')
          .attr('stroke', legendByKeys[key].color)
          .attr('stroke-width', 2)
          .style('pointer-events', 'none')
          .attr('d', line);
        paths.push(path);

        svg
          .append('g')
          .attr('transform', `translate(${margin.left},${margin.top})`)
          .selectAll('.dot')
          .data([data[key][0], data[key][data[key].length - 1]])
          .enter()
          .append('circle')
          .style('pointer-events', 'none')
          .attr('fill', legendByKeys[key].color)
          .attr('cx', (d) => xScale(d.x))
          .attr('cy', (d) => yScale(d.y))
          .attr('r', 4); // Adjust the radius as needed
      }
    }

    // Add tooltip
    const tooltip = d3.select(tooltipRef.current);

    // Add invisible overlay for mouse events
    const overlay = g
      .append('rect')
      .attr('width', chartWidth)
      .attr('height', chartHeight)
      .style('fill', 'none')
      .style('z-index', 2)
      .style('pointer-events', 'all');

    // Add circle for highlighting data points
    const highlight = g
      .append('circle')
      .attr('r', 4)
      .style('opacity', 0)
      .style('pointer-events', 'none');

    overlay.on('mousemove', (event) => {
      const enabledSerieKeys = Object.keys(data).filter((key) => legendByKeys[key].enabled);
      if (enabledSerieKeys.length === 0) {
        return;
      }
      const currentSerieKey = enabledSerieKeys[0];

      highlight.style('fill', legendByKeys[currentSerieKey].color);

      const currentData = data[currentSerieKey];
      const [xPos, yPos] = d3.pointer(event);
      const bisectVal = d3.bisector<DataPointWithExtra, number>((d) => d.x).left;
      const x0 = xScale.invert(xPos);
      const i = bisectVal(currentData, x0, 1);
      if (i < 0 || i >= currentData.length) {
        return;
      }
      const d0 = currentData[i - 1];
      const d1 = currentData[i];
      const d = x0 - d0.x > d1.x - x0 ? d1 : d0;
      highlight.attr('cx', xScale(d.x)).attr('cy', yScale(d.y)).style('opacity', 1);
      const tooltipWidth = 120;
      const tooltipHeight = 40;

      let tooltipX = xPos + margin.left + 10;

      let tooltipY = yScale(d.y) + margin.top - 30;
      // let tooltipY = yPos + margin.top - tooltipHeight - 10;

      // if (tooltipY < 0) {
      //   tooltipY = yPos + margin.top + 10;
      // })
      let tooltipHtml = '';
      if (currentSerieKey === 'track') {
        tooltipHtml = `${formatTimeDuration(Math.round(d.x))}
          <br/>${
            d?.reverseCumulativeStreams !== undefined
              ? `${numberToShorthand(Math.round(d?.reverseCumulativeStreams))} ${
                  d?.reverseCumulativeStreams !== 1 ? t(`streams`) : t(`stream`)
                }, `
              : ''
          }
          ${Math.round(d.y)}%`;
      } else {
        tooltipHtml = `<b>${formatTimeDuration(Math.round(d.x))}</b>
          <br/>${Math.round(d.y)}%`;
      }
      tooltip
        .style('opacity', 1)
        .html(tooltipHtml)
        .style('background-color', '#111')
        .style('border', 'none')
        .style('border-radius', '5px')
        .style('color', '#fff')
        .style('text-align', `center`)
        .style('font-size', `14px`)
        .style('pointer-events', 'none')
        .style('z-index', `1`)
        // .style('margin-top', `-50%`)
        .style('left', `${tooltipX}px`)
        .style('white-space', `nowrap`)
        .style('top', `${tooltipY}px`);

      if (tooltipX + tooltipWidth > width) {
        tooltipX =
          xPos + margin.left - (tooltip.node() as unknown as HTMLDivElement).clientWidth - 10;
        tooltip.style('left', `${tooltipX}px`);
      }
    });

    overlay.on('mouseout', () => {
      highlight.style('opacity', 0);
      tooltip.style('opacity', 0);
    });

    // Animation (optional)
    // if (animate) {
    //   const path = paths[0];
    //   const totalLength = (path.node() as SVGPathElement).getTotalLength();
    //   path
    //     .attr('stroke-dasharray', `${totalLength} ${totalLength}`)
    //     .attr('stroke-dashoffset', totalLength)
    //     .transition()
    //     .duration(2000)
    //     .ease(d3.easeLinear)
    //     .attr('stroke-dashoffset', 0);
    // }
  }, [data, width, height, margin, legendByKeys, curveFactor, animate]);

  return (
    <div style={{ width: '100%', height: `${height}px`, position: 'relative' }}>
      <svg ref={svgRef} viewBox={`0 0 ${width} ${height}`} style={{ display: 'block' }} />
      <div
        ref={tooltipRef}
        style={{
          position: 'absolute',
          opacity: 0,
          backgroundColor: 'white',
          border: '1px solid #ddd',
          padding: '10px',
          pointerEvents: 'none',
        }}
      />
    </div>
  );
};
