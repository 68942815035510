import React from 'react';
import { Box } from '@mui/material';

import featureLists from 'utils/featureLists';
import { SongFundTabs, SongFundTab } from 'components/songfund-tabs';

import FugaAnalytics from './FugaAnalytics';
import SocialAnalytics from './SocialAnalytics';
import TrackAnalytics from './TrackAnalytics';
import { useTranslation } from 'react-i18next';
import UnderMaintenance from './UnderMaintenance';

const Analytics: React.FC = () => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = React.useState(1);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <Box
        maxWidth="inherit"
        overflow="auto"
        sx={{
          maxWidth: '100vw',
        }}
      >
        <Box minWidth="500px" marginX="auto">
          <SongFundTabs
            value={selectedTab}
            onChange={handleChangeTab}
            centered
            aria-label="admin tabs"
          >
            <SongFundTab label={t('Streaming Analytics')} />
            {featureLists.SOCIAL_ANALYTICS && <SongFundTab label={t('Social Analytics')} />}
            {featureLists.TRACK_ANALYTICS && <SongFundTab label={t('Track Completion Rate')} />}
          </SongFundTabs>
        </Box>
      </Box>

      <Box>
        {/* {selectedTab === 0 && <FugaAnalytics />} */}
        {selectedTab === 0 && <UnderMaintenance />}
        {selectedTab === 1 && <SocialAnalytics />}
        {selectedTab === 2 && <TrackAnalytics />}
      </Box>
    </Box>
  );
};

export default Analytics;
