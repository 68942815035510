import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { colors } from 'styles/colors';
import Text from 'components/text';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

interface IProps {
  value: number;
}

const PROGRESS_METER_SIZE = 150;

const getProgressLabel = (t: TFunction, value: number) => {
  if (value < 25) {
    return t('Low');
  }
  if (value < 50) {
    return t('Medium');
  }
  if (value < 85) {
    return t('High');
  }
  return t('Strong');
};

const getProgressLabelColor = (value: number) => {
  if (value < 25) {
    return '#DC8743';
  }
  if (value < 50) {
    return '#E2BB0D';
  }
  if (value < 85) {
    return '#64CE27';
  }
  return colors.main.purpleDark;
};

export default function ProgressMeter(props: IProps) {
  const { t } = useTranslation();
  const { value } = props;

  return (
    <Box
      sx={{
        width: '100%',
        position: 'static',
        top: '72px',
        left: 0,
        textAlign: 'center',
      }}
      display={{
        xs: 'none',
        sm: 'none',
        md: 'block',
      }}
    >
      <Box position="relative" display="inline-block">
        <CircularProgress
          variant="determinate"
          className="progress-meter"
          sx={{
            zIndex: 2,
            position: 'relative',
            color: getProgressLabelColor(value),
          }}
          size={PROGRESS_METER_SIZE}
          thickness={2.5}
          value={value}
        />
        <CircularProgress
          variant="determinate"
          className="progress-meter"
          sx={{
            left: 0,
            position: 'absolute',
            color: '#ddd',
          }}
          size={PROGRESS_METER_SIZE}
          thickness={2.5}
          value={100}
        />
        <Box
          top={0}
          left={0}
          right={0}
          bottom={0}
          display="flex"
          position="absolute"
          alignItems="center"
          paddingBottom="4px"
          justifyContent="center"
        >
          <Text
            fontSize="18px"
            fontWeight="bold"
            color={getProgressLabelColor(value)}
            letterSpacing="1.5px"
          >
            {getProgressLabel(t, value)}
          </Text>
        </Box>
        <Box position="absolute" bottom={-25} left={0} right={0}>
          <Text>{t('Confidence Level')}</Text>
        </Box>
      </Box>
    </Box>
  );
}
