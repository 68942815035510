import React, { useCallback, useEffect, useState } from 'react';
// mui component
import { Grid, Typography } from '@mui/material';
// import { Button } from '@mui/material';
// import { styled } from '@mui/material/styles';
// components
import CreateDealModal from './components/create-deal-modal';
// components
import DealItem from '../../components/deal-item';
import InvitedDealItem from 'pages/profile/components/invited-deal-item';
// custom hooks
import { useGetDealList } from 'hooks/swrRequests';
// styles
import { fontStyles } from 'styles/font-styles';
import { colors } from 'styles/colors';
import goToPendingReceipts from 'utils/goToPendingReceipts';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';

// const StyledButton = styled(Button)(({ theme }) => ({
//   width: '174px',
//   height: '48px',
//   backgroundColor: theme.colors.main.purple,
//   ...theme.fontStyles.body,
//   color: theme.colors.main.white,
//   textTransform: 'unset',
//   '&:hover': {
//     backgroundColor: theme.colors.main.purpleDark,
//   },
// }));

const DealsSection: React.FC<WithTranslation> = ({ t, i18n }) => {
  const { data: deals } = useGetDealList();
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (deals) {
      goToPendingReceipts(deals);
    }
  }, [deals]);

  // const handleOpenModal = useCallback(() => {
  //   setOpenModal(true);
  // }, []);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  return (
    <Grid
      container
      flexDirection="column"
      sx={{ maxWidth: '842px', margin: { xs: '16px', md: '40px' } }}
    >
      <Grid container justifyContent="space-between">
        <Grid container item xs={12} sm={6} sx={{ marginBottom: { xs: '10px', md: 0 } }}>
          <Typography sx={{ ...fontStyles.h3, color: colors.text.light.title }}>
            {t('Your Deals')}
          </Typography>
        </Grid>
        {/* <Grid
          container
          item
          xs={12}
          sm={6}
          sx={{ justifyContent: { xs: 'flex-start', sm: 'flex-end' } }}
        >
          <StyledButton variant="contained" onClick={handleOpenModal}>
            Create new deal
          </StyledButton>
        </Grid> */}
      </Grid>
      <Grid container flexDirection="column" sx={{ marginTop: '40px' }}>
        {deals?.map((item) => {
          return item.invitationId ? (
            <InvitedDealItem key={item.id} {...item} />
          ) : (
            <DealItem key={item.id} {...item} />
          );
        })}
      </Grid>
      <CreateDealModal open={openModal} handleClose={handleCloseModal} />
    </Grid>
  );
};

export default withTranslation()(DealsSection);
