import { Avatar, Box, Grid } from '@mui/material';
import star from 'assets/icons/star.svg';
import Text from 'components/text';
import { IDeal, IRecoupment } from 'hooks/useUserData';
import { useCallback, useEffect, useState } from 'react';
import { colors } from 'styles/colors';
import { formatValueToDollars } from 'utils/helpers';
import { RecoupmentStatus } from '../sections/admin-section/Renewals';
import {
  StyledAmount,
  StyledHeading,
  StyledLabel,
  StyledValue,
} from '../sections/styled-components';
import Recoupment from './recoupment';
import { useTranslation } from 'react-i18next';

const InvitedDealItem: React.FC<IDeal> = ({
  id,
  images,
  amount,
  artistName,
  recoupments,
  lengthOfCommitment,
}) => {
  const { t } = useTranslation();
  const [dealRecoupments, setDealRecoupments] = useState<IRecoupment[]>([]);

  useEffect(() => {
    if (recoupments && recoupments.length > 0) {
      setDealRecoupments(recoupments);
    }
  }, [recoupments]);

  const isSignedArtist = (lengthOfCommitment || 0) > 0 && (amount || 0) > 0;

  const onRecoupmentRenew = useCallback(
    (recoupment: IRecoupment) => {
      if (dealRecoupments) {
        setDealRecoupments(
          dealRecoupments.map((iterRecoupment) => {
            if (iterRecoupment.id === recoupment.id) {
              return { ...iterRecoupment, status: RecoupmentStatus.APPROVED };
            } else {
              return iterRecoupment;
            }
          })
        );
      }
    },
    [dealRecoupments]
  );

  return (
    <Grid
      container
      sx={{
        padding: '32px',
        marginBottom: '32px',
        border: `1px solid ${colors.background.light.b4}`,
        borderRadius: '8px',
        boxShadow: '2px 1px 15px rgba(44, 59, 70, 0.2)',
        transition: 'box-shadow 0.4s ease-in-out',
        '&:hover': {
          boxShadow: '18px 18px 10px rgb(44 59 70 / 20%)',
        },
      }}
    >
      <Grid container item md={2} xs={12}>
        <Avatar src={images[0]?.url || star} alt="avatar" sx={{ width: '88px', height: '88px' }} />
      </Grid>
      <Grid container flexDirection="column" item md={10}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{
            paddingBottom: '16px',
            '& svg': {
              width: '16px',
              height: '16px',
              transform: 'rotate(180deg)',
            },
            alignItems: { xs: 'flex-end', md: 'center' },
          }}
        >
          <StyledHeading sx={{ paddingTop: { xs: '20px', md: '0' } }}>
            {artistName || '-'}
          </StyledHeading>
        </Grid>

        <Grid
          container
          alignItems="center"
          sx={{
            padding: '16px 0',
            borderTop: `1px solid ${colors.background.light.b4}`,
            borderBottom: `1px solid ${colors.background.light.b4}`,
          }}
        >
          {isSignedArtist ? (
            <Box marginBottom={-2}>
              {dealRecoupments && dealRecoupments.length > 0 ? (
                <>
                  {dealRecoupments.map((recoupmentData) => (
                    <Recoupment
                      key={recoupmentData.id}
                      {...recoupmentData}
                      onUpdate={onRecoupmentRenew}
                    />
                  ))}
                </>
              ) : (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box
                        marginBottom={2}
                        padding={2}
                        bgcolor="#eee"
                        fontSize={18}
                        borderRadius={1.2}
                      >
                        <Text>
                          {t('Your recoupment data will be updated soon! Please check later.')}
                        </Text>
                      </Box>
                    </Grid>
                    <Grid
                      container
                      flexDirection="column"
                      item
                      xs={12}
                      md={4}
                      sx={{ marginBottom: { xs: '15px', md: '15px' } }}
                    >
                      <StyledLabel>{t('Amount')}:</StyledLabel>
                      <StyledValue paddingRight="12px">
                        <StyledAmount as="span">{formatValueToDollars(amount ?? 0)}</StyledAmount>
                      </StyledValue>
                    </Grid>
                    <Grid
                      container
                      flexDirection="column"
                      item
                      xs={12}
                      md={4}
                      sx={{ marginBottom: { xs: '15px', md: '15px' } }}
                    >
                      <StyledLabel>{t('Term Length')}:</StyledLabel>
                      <StyledValue paddingRight="12px">
                        <Text component="span">
                          {t('months_count', { count: Number(lengthOfCommitment) * 12 })}
                        </Text>
                      </StyledValue>
                    </Grid>
                  </Grid>
                </>
              )}
            </Box>
          ) : (
            <Grid
              container
              flexDirection="column"
              item
              xs={12}
              sx={{ marginBottom: { xs: '15px', md: '0' } }}
            >
              <Box padding={2} bgcolor="#eee" fontSize={18} borderRadius={1.2}>
                <Text>{t('We are revewing your reports and your application is pending.')}</Text>
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InvitedDealItem;
