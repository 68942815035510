import React, { useCallback, useState } from 'react';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// mui components
import { Grid, Typography } from '@mui/material';
// layouts
import AuthLayout from 'layouts/auth-layout';
// components
import StyledInputContainer from '../components/styled-input-container';
import StyledButton from '../components/styled-button';
import StyledLink from '../components/styled-link';
import DialogModal from 'components/dialog-modal';
import ErrorModal from 'components/error-modal';
// custom hooks
import { useError } from 'hooks/useError';
// controllers
import BaseInputController from 'controllers/base-input-controller';
// api
import api from 'utils/api';
// validations
import { ForgotPasswordSchema } from './validation';
// styles
import { fontStyles } from 'styles/font-styles';
import { colors } from 'styles/colors';
import { useTranslation } from 'react-i18next';

const ForgotPassword: React.FC = () => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);

  const [openErrorModal, setOpenErrorModal] = useState(false);

  const { message, setError } = useError();

  const form = useForm({ resolver: yupResolver(ForgotPasswordSchema(t)) });

  const email = useWatch({
    control: form.control,
    name: 'email',
  });

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleCloseErrorModal = useCallback(() => {
    setOpenErrorModal(false);
  }, []);

  const onSubmit = useCallback(
    async (formValues: object) => {
      try {
        await api.auth.resetPassword({ data: formValues });
        setOpenModal(true);
      } catch (err) {
        setError(err);
        setOpenErrorModal(true);
      }
    },
    [setError]
  );

  return (
    <FormProvider {...form}>
      <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
        <AuthLayout>
          <Grid container flexDirection="column" alignItems="center" sx={{ maxWidth: '500px' }}>
            <Typography sx={{ ...fontStyles.h3, color: colors.main.white }}>
              {t('Forgot password')}
            </Typography>
            <Typography
              sx={{
                maxWidth: '390px',
                margin: '40px 0',
                ...fontStyles.body,
                color: colors.text.dark.body,
                textAlign: 'center',
              }}
            >
              {t('forgot-password.instructions')}
            </Typography>
            <StyledInputContainer>
              <BaseInputController
                dark
                withError
                name="email"
                type="email"
                label={t('Email address')}
              />
            </StyledInputContainer>
            <StyledButton type="submit">{t('Recover')}</StyledButton>
            <Grid container justifyContent="center" alignItems="baseline">
              <StyledLink to="/sign-in">{t('Back to Log in')}</StyledLink>
            </Grid>
          </Grid>
        </AuthLayout>
        <DialogModal
          dark
          open={openModal}
          maxWidth="410px"
          withActions={false}
          handleClose={handleCloseModal}
        >
          <Grid container justifyContent="center">
            <Grid container justifyContent="center" sx={{ maxWidth: '282px' }}>
              <Typography
                sx={{
                  fontSize: '18px',
                  lineHeight: '26px',
                  fontWeight: 'lighter',
                  color: colors.text.dark.label,
                  textAlign: 'center',
                }}
              >
                {t('forgot-password.email-will-be-sent')}
              </Typography>
              <StyledLink to="/sign-in" sx={{ marginTop: '32px' }}>
                {t('Back to Log in')}
              </StyledLink>
            </Grid>
          </Grid>
        </DialogModal>
        <ErrorModal open={openErrorModal} message={message} handleClose={handleCloseErrorModal} />
      </form>
    </FormProvider>
  );
};

export default ForgotPassword;
