import { Box, CircularProgress } from '@mui/material';
import Text from 'components/text';
import { useTranslation } from 'react-i18next';

export default function Loading() {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      flexDirection="row"
      gap={2}
      padding={6}
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress size={22} sx={{ color: '#333' }} />
      <Text color="#333" fontSize={16} resetFont>
        {t('Preparing analytics data...')}
      </Text>
    </Box>
  );
}
