import React, { HTMLInputTypeAttribute, useCallback, useMemo, useState } from 'react';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
// mui components
import { Grid } from '@mui/material';
// components
import {
  StyledLabel,
  StyledValue,
  StyledEditButton,
  StyledSubmitButton,
} from './styled-components';
import ErrorModal from 'components/error-modal';
// custom hooks
import { IUserData, useUserData } from 'hooks/useUserData';
import { useError } from 'hooks/useError';
// api
import api from 'utils/api';
// controllers
import BaseInputController from 'controllers/base-input-controller';
// validations
import { emailRule } from 'utils/validation-rules';
import { useTranslation } from 'react-i18next';

interface OwnProps {
  label: string;
  name: string;
  disabledEdit?: boolean;
  type?: HTMLInputTypeAttribute;
}

const ChangedInput: React.FC<OwnProps> = ({ label, name, type = 'text', disabledEdit = false }) => {
  const { t } = useTranslation();
  const { message, setError } = useError();

  const [isSubmitting, setSubmitting] = useState(false);

  const [openErrorModal, setOpenErrorModal] = useState(false);

  const { userData, updateUserData } = useUserData();

  const defaultValues = useMemo(() => {
    if (userData[name as keyof IUserData]) {
      return { [name]: userData[name as keyof IUserData] };
    }

    return { [name]: '' };
  }, [userData, name]);

  const defaultInputValue = defaultValues[name];

  const Schema = yup.object({
    [name]: name === 'email' ? emailRule : yup.string().required(t('Field is required')),
  });

  const form = useForm({ defaultValues, resolver: yupResolver(Schema) });

  const [showInput, setShowInput] = useState(false);

  const currentValue = useWatch({
    control: form.control,
    name,
  });

  const handleToggleInput = useCallback(() => {
    setShowInput((prevState) => !prevState);
  }, []);

  const onSubmit = useCallback(
    async (formValues: any) => {
      try {
        setSubmitting(true);
        await api.auth.updateProfile({ data: formValues });
        updateUserData(formValues);
        setShowInput(false);
        form.reset(formValues);
      } catch (err) {
        setError(err);
        setOpenErrorModal(true);
      } finally {
        setSubmitting(false);
      }
    },
    [updateUserData, form, setError]
  );

  return (
    <>
      <FormProvider {...form}>
        <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
          <Grid
            container
            flexDirection="column"
            sx={{ padding: '26px 0 24px', boxShadow: 'inset 0px -1px 0px #DFE1E6' }}
          >
            <Grid
              container
              alignItems="flex-end"
              justifyContent="space-between"
              sx={{ marginBottom: '12px' }}
            >
              <StyledLabel>{label}</StyledLabel>
              <StyledEditButton disableRipple onClick={handleToggleInput} disabled={disabledEdit}>
                {showInput ? t('Cancel') : t('Edit')}
              </StyledEditButton>
            </Grid>
            {showInput ? (
              <Grid container flexDirection="column">
                <BaseInputController
                  withError
                  name={name}
                  type={type}
                  label={label}
                  defaultValue={defaultInputValue}
                />
                <StyledSubmitButton loading={isSubmitting}>{t('Save')}</StyledSubmitButton>
              </Grid>
            ) : (
              <StyledValue>{currentValue}</StyledValue>
            )}
          </Grid>
        </form>
      </FormProvider>
      <ErrorModal
        open={openErrorModal}
        handleClose={() => setOpenErrorModal(false)}
        message={message}
      />
    </>
  );
};

export default ChangedInput;
